import { FC, useCallback, useState } from "react";
import { Formik, FormikHelpers } from "formik";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

const LOGIN = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      errors {
        key
        message
      }
      session {
        token
      }
      requires_2fa
      loginToken
    }
  }
`;

const VERIFY_TOTP = gql`
  mutation VerifyTOTP($loginToken: String!, $totpCode: String!) {
    verifyTotp(loginToken: $loginToken, totpCode: $totpCode) {
      errors {
        key
        message
      }
      session {
        token
        user {
          id
        }
      }
    }
  }
`;

interface LoginMutationData {
  login: {
    errors?: InputError[];
    session?: {
      token: string;
    };
    requires_2fa?: boolean;
    loginToken?: string;
  };
}

interface TOTPMutationData {
  verifyTotp: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

interface FormValues {
  email: string;
  password: string;
  totpCode: string;
}

interface LoginInput {
  email: string;
  password: string;
}

interface VerifyTOTPInput {
  loginToken: string;
  totpCode: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .required("Required"),
    password: Yup.string().required(),
  })
  .required();

interface SignInFormProps {
  onSignIn(jwt: string): any;
}

export const SignInForm: FC<SignInFormProps> = (props) => {
  const { onSignIn } = props;
  // loginToken is to be used with the verifyTotp mutation
  const [loginToken, setLoginToken] = useState<string | null>(null);

  const [loginMut] = useMutation<LoginMutationData>(LOGIN);
  const [verifyTotpMut] = useMutation<TOTPMutationData>(VERIFY_TOTP);

  const login = useCallback(
    async (values: LoginInput, formikHelpers: FormikHelpers<FormValues>) => {
      const { setStatus } = formikHelpers;

      const { data } = await loginMut({ variables: values });

      if (data?.login.errors) {
        setStatus({ errors: data.login.errors });
      } else if (data?.login.requires_2fa && data?.login.loginToken) {
        setLoginToken(data.login.loginToken);
      } else if (data?.login.session) {
        const { token } = data.login.session;
        return onSignIn(token);
      }
    },
    [loginMut, onSignIn]
  );

  const verifyTotp = useCallback(
    async (
      values: VerifyTOTPInput,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      const { setStatus } = formikHelpers;
      setStatus({ errors: null });

      const { data } = await verifyTotpMut({ variables: values });

      if (data?.verifyTotp.errors) {
        setStatus({ errors: data.verifyTotp.errors });
      } else if (data?.verifyTotp.session) {
        const { token } = data.verifyTotp.session;
        return onSignIn(token);
      }
    },
    [verifyTotpMut, onSignIn]
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikHelpers;
      setStatus({ errors: null });

      try {
        if (loginToken) {
          const verifyTotpInput = { loginToken, totpCode: values.totpCode };
          return verifyTotp(verifyTotpInput, formikHelpers);
        } else {
          const loginInput = { email: values.email, password: values.password };
          return login(loginInput, formikHelpers);
        }
      } catch (e) {
        console.error(e);
        setStatus({ errors: [{ key: "", message: "Something went wrong." }] });
      } finally {
        setSubmitting(false);
      }
    },
    [login, loginToken, verifyTotp]
  );

  return (
    <Formik
      initialValues={{ email: "", password: "", totpCode: "" }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className="md:px-6 rounded-xl px-2 py-6 bg-white border shadow-md shadow-xl"
        >
          <h4 className="my-4 text-xl font-semibold text-center">
            Sign in to your account
          </h4>

          <FormStatusErrors status={status} />

          <TextField
            label="Email"
            type="email"
            name="email"
            icon="envelope"
            placeholder="email@example.com"
          />

          <div className="mt-3">
            <TextField
              label="Password"
              type="password"
              placeholder="••••••••••"
              name="password"
              icon="lock"
            />
          </div>
          {/* If loginToken is set, show the TOTP input field */}
          {loginToken ? (
            <div className="mt-3">
              <TextField
                label="TOTP Code"
                type="text"
                placeholder="123456"
                name="totpCode"
                icon="key"
              />
            </div>
          ) : null}

          <div className="flex justify-center mt-6">
            <Button
              type="submit"
              kind="primary"
              color="teal"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Sign In
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
