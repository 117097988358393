import { FC, useCallback, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik, FormikHelpers, useFormikContext } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { TextField, TextInput } from "components/formik/TextField";
import { Spinner } from "components/Spinner";
import { Modal, ModalHeader } from "components/Modal";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { PhoneMaskField } from "components/formik/PhoneMaskField";
import { SelectField } from "components/formik/SelectField";
import { PlainSelectField } from "components/formik/PlainSelectField";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { Button } from "components/Button";
import { VerticalField } from "components/formik/FieldStructure";
import {
  CallNotesInput,
  combineCallNotes,
  procedureDateLabel,
} from "../../AppointmentRequestConfirmationPage/ConfirmationForm/CallNotesInput";
import { FAIcon } from "components/FAIcon";

const APPOINTMENT_REQUEST_QUERY = gql`
  query AppointmentRequestContactDetails($appointmentRequestId: UUID4!) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      requestingProviderName
      requestingProviderFirstName
      requestingProviderLastName
      requestingProviderCredential
      nameOfContactPerson
      contactPersonFirstName
      contactPersonLastName
      contactPersonCredential
      contactPersonLocation
      whoIsPerformingP2p
      otherP2pContactName
      otherP2pContactFirstName
      otherP2pContactLastName
      otherP2pContactCredential
      contactPhoneNumber {
        raw
        formatted
      }
      contactPhoneNumberExtension
      alternateContactPhoneNumber {
        raw
        formatted
      }
      alternateContactPhoneNumberExtension
      contactEmail
      contactInstructions
      callNotes
      contactDetailsUpdatedAt
    }
  }
`;

interface AppointmentRequestContactDetails {
  id: string;
  requestingProviderName: string | null;
  requestingProviderFirstName: string | null;
  requestingProviderLastName: string | null;
  requestingProviderCredential: string | null;
  nameOfContactPerson: string | null;
  contactPersonFirstName: string | null;
  contactPersonLastName: string | null;
  contactPersonCredential: string | null;
  contactPersonLocation: string | null;
  whoIsPerformingP2p: string | null;
  otherP2pContactName: string | null;
  otherP2pContactFirstName: string | null;
  otherP2pContactLastName: string | null;
  otherP2pContactCredential: string | null;
  otherP2pOtherContactCredential: string | null;
  contactPhoneNumber: {
    raw: string;
    formatted: string;
  };
  contactPhoneNumberExtension: string | null;
  alternateContactPhoneNumber: null | {
    raw: string;
    formatted: string;
  };
  alternateContactPhoneNumberExtension: string | null;
  contactEmail: string | null;
  contactInstructions: string | null;
  callNotes?: string[];
  contactDetailsUpdatedAt: string | null;
}

interface Data {
  appointmentRequest: AppointmentRequestContactDetails;
}

interface Variables {
  appointmentRequestId: string;
}

const contactPersonLocationOptions: Option[] = [
  {
    value: "Provider Office",
    label: "Provider Office",
  },
  {
    value: "Health Plan",
    label: "Health Plan",
  },
  {
    value: "Other",
    label: "Other",
  },
];

const credentialOptions: Option[] = [
  { value: "", label: "Select..." },
  ...[
    "MD",
    "NP",
    "PA",
    "DO",
    "APN/APRN",
    "OB",
    "PT",
    "OT",
    "ST",
    "DC",
    "Acupuncturist",
    "MT",
    "Genetic Counselor",
    "Other",
  ].map((val) => ({
    value: val,
    label: val,
  })),
];

const whoIsPerformingP2pOptions: Option[] = [
  {
    value: "Requesting Provider",
    label: "Requesting Provider",
  },
  {
    value: "Contact Person",
    label: "Contact Person",
  },
  {
    value: "Other",
    label: "Someone else",
  },
];

const UPDATE_CONTACT_DETAILS = gql`
  mutation UpdateContactDetails(
    $appointmentRequestId: UUID4!
    $contactDetails: ContactDetailsInput!
  ) {
    updateAppointmentRequestContactDetails(
      id: $appointmentRequestId
      contactDetails: $contactDetails
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        requestingProviderName
        requestingProviderFirstName
        requestingProviderLastName
        requestingProviderCredential
        nameOfContactPerson
        contactPersonFirstName
        contactPersonLastName
        contactPersonCredential
        contactPersonLocation
        whoIsPerformingP2p
        otherP2pContactName
        otherP2pContactFirstName
        otherP2pContactLastName
        otherP2pContactCredential
        contactPhoneNumber {
          raw
        }
        contactPhoneNumberExtension
        alternateContactPhoneNumber {
          raw
        }
        alternateContactPhoneNumberExtension
        contactEmail
        contactInstructions
        callNotes
        contactDetailsUpdatedAt
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestContactDetails: {
    errors?: InputError[];
    appointmentRequest?: AppointmentRequestContactDetails;
  };
}

interface FormValues {
  requestingProviderFirstName: string;
  requestingProviderLastName: string;
  requestingProviderCredential: string;
  otherRequestingProviderCredential: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  contactPersonLocation: string;
  contactPersonCredential: string;
  otherContactPersonCredential: string;
  whoIsPerformingP2p: string;
  otherP2pContactFirstName: string;
  otherP2pContactLastName: string;
  otherP2pContactCredential: string;
  otherP2pContactOtherCredential: string;
  contactPhoneNumber: string;
  contactPhoneNumberExtension: string;
  alternateContactPhoneNumber: string;
  alternateContactPhoneNumberExtension: string;
  contactEmail: string;
  contactInstructions: string;
  callNotes: string[];
  procedureDate?: string;
}

interface UpdateContactDetailsModalProps {
  appointmentRequestId: string;
  isOpen: boolean;
  onClose(): void;
  refetchQueries: any;
}

export const UpdateContactDetailsModal: FC<UpdateContactDetailsModalProps> = (
  props
) => {
  const { appointmentRequestId, isOpen, onClose, refetchQueries } = props;
  const toast = useToast();
  const { data, loading, error } = useQuery<Data, Variables>(
    APPOINTMENT_REQUEST_QUERY,
    { variables: { appointmentRequestId }, fetchPolicy: "network-only" }
  );
  const [updateContactDetails] = useMutation<MutationData>(
    UPDATE_CONTACT_DETAILS
  );

  function requestingProviderFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.requestingProviderName?.split(" ") || [];
    return appointmentRequest.requestingProviderFirstName || firstName;
  }

  function requestingProviderLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.requestingProviderName?.split(" ") || [];
    return (
      appointmentRequest.requestingProviderLastName || lastNameParts.join(" ")
    );
  }

  function requestingProviderCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.requestingProviderCredential || "")
      ? appointmentRequest.requestingProviderCredential
      : "Other";
  }

  function otherRequestingProviderCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.requestingProviderCredential || "")
      ? ""
      : appointmentRequest.requestingProviderCredential || "";
  }

  function contactPersonFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.nameOfContactPerson?.split(" ") || [];
    return appointmentRequest.contactPersonFirstName || firstName;
  }

  function contactPersonLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.nameOfContactPerson?.split(" ") || [];
    return appointmentRequest.contactPersonLastName || lastNameParts.join(" ");
  }

  function contactPersonCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.contactPersonCredential || "")
      ? appointmentRequest.contactPersonCredential || ""
      : "Other";
  }

  function otherContactPersonCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.contactPersonCredential || "")
      ? ""
      : appointmentRequest.contactPersonCredential || "";
  }

  function otherP2pContactFirstName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [firstName] =
      appointmentRequest?.otherP2pContactName?.split(" ") || [];
    return appointmentRequest.otherP2pContactFirstName || firstName;
  }

  function otherP2pContactLastName(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    const [, ...lastNameParts] =
      appointmentRequest?.otherP2pContactName?.split(" ") || [];
    return (
      appointmentRequest.otherP2pContactLastName || lastNameParts.join(" ")
    );
  }

  function otherP2pContactCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.otherP2pContactCredential || "")
      ? appointmentRequest.otherP2pContactCredential || ""
      : "Other";
  }

  function otherP2pContactOtherCredential(
    appointmentRequest: AppointmentRequestContactDetails
  ): string | null {
    return credentialOptions
      .map((opt) => opt.value)
      .includes(appointmentRequest.otherP2pContactCredential || "")
      ? ""
      : appointmentRequest.otherP2pContactCredential || "";
  }

  function callNotes(
    appointmentRequest: AppointmentRequestContactDetails
  ): string[] {
    return (
      appointmentRequest.callNotes?.map((callNote) => {
        if (callNote.includes(procedureDateLabel)) {
          return procedureDateLabel;
        } else {
          return callNote;
        }
      }) || []
    );
  }

  function procedureDate(
    appointmentRequest: AppointmentRequestContactDetails
  ): string {
    const procedureDateNote = appointmentRequest.callNotes?.find((callNote) =>
      callNote.includes(procedureDateLabel)
    );
    return procedureDateNote?.split(" ").pop() || "";
  }

  const onSubmit = useCallback(
    async (
      formValues: FormValues,
      formikHelpers: FormikHelpers<FormValues>
    ) => {
      const { setStatus, setSubmitting, setFieldError } = formikHelpers;

      setStatus({ errors: null });

      const extraErrors = validateExtras(formValues);

      if (Object.keys(extraErrors).length > 0) {
        for (let key in extraErrors) {
          setFieldError(key, extraErrors[key]);
        }
        setSubmitting(false);
        return;
      }

      const values = combineCallNotes("callNotes", formValues);

      const variables = {
        appointmentRequestId,
        contactDetails: {
          ...values,
          requestingProviderCredential:
            values.requestingProviderCredential === "Other"
              ? values.otherRequestingProviderCredential.trim()
              : values.requestingProviderCredential,
          otherRequestingProviderCredential: undefined,
          contactPersonCredential:
            values.contactPersonCredential === "Other"
              ? values.otherContactPersonCredential.trim()
              : values.contactPersonCredential,
          otherContactPersonCredential: undefined,
          otherP2pContactCredential:
            values.otherP2pContactCredential === "Other"
              ? values.otherP2pContactOtherCredential.trim()
              : values.otherP2pContactCredential,
          otherP2pContactOtherCredential: undefined,
        },
      };

      try {
        const { data } = await updateContactDetails({
          variables,
          refetchQueries,
        });

        if (data?.updateAppointmentRequestContactDetails.errors) {
          setStatus({
            errors: data.updateAppointmentRequestContactDetails.errors,
          });
        } else if (
          data?.updateAppointmentRequestContactDetails.appointmentRequest
        ) {
          // it worked.
          toast.success("Contact Details Updated.");
          return onClose();
        }
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        setStatus({
          errors: [{ key: "", message: "Something went wrong." }],
        });
        setSubmitting(false);
      }
    },
    [toast, appointmentRequestId, updateContactDetails, onClose, refetchQueries]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="w-full max-w-3xl"
    >
      <div className="_UpdateContactDetailsModal bg-white rounded-lg shadow-lg">
        <ModalHeader
          icon={["far", "address-card"]}
          title="Update Contact Details"
          onClose={onClose}
        />
        {loading ? (
          <Spinner />
        ) : error || !data?.appointmentRequest ? (
          <p>Failed to load.</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              requestingProviderFirstName:
                requestingProviderFirstName(data.appointmentRequest) || "",
              requestingProviderLastName:
                requestingProviderLastName(data.appointmentRequest) || "",
              requestingProviderCredential:
                requestingProviderCredential(data.appointmentRequest) || "",
              otherRequestingProviderCredential:
                otherRequestingProviderCredential(data.appointmentRequest) ||
                "",
              contactPersonFirstName:
                contactPersonFirstName(data.appointmentRequest) || "",
              contactPersonLastName:
                contactPersonLastName(data.appointmentRequest) || "",
              contactPersonCredential:
                contactPersonCredential(data.appointmentRequest) || "",
              otherContactPersonCredential:
                otherContactPersonCredential(data.appointmentRequest) || "",
              contactPersonLocation:
                data.appointmentRequest.contactPersonLocation || "",
              contactPhoneNumber:
                data.appointmentRequest.contactPhoneNumber.raw,
              whoIsPerformingP2p:
                data.appointmentRequest.whoIsPerformingP2p || "",
              otherP2pContactFirstName:
                otherP2pContactFirstName(data.appointmentRequest) || "",
              otherP2pContactLastName:
                otherP2pContactLastName(data.appointmentRequest) || "",
              otherP2pContactCredential:
                otherP2pContactCredential(data.appointmentRequest) || "",
              otherP2pContactOtherCredential:
                otherP2pContactOtherCredential(data.appointmentRequest) || "",
              contactPhoneNumberExtension:
                data.appointmentRequest.contactPhoneNumberExtension || "",
              alternateContactPhoneNumber:
                data.appointmentRequest.alternateContactPhoneNumber?.raw || "",
              alternateContactPhoneNumberExtension:
                data.appointmentRequest.alternateContactPhoneNumberExtension ||
                "",
              contactInstructions:
                data.appointmentRequest.contactInstructions || "",
              callNotes: callNotes(data.appointmentRequest),
              procedureDate: procedureDate(data.appointmentRequest),
              contactEmail: data.appointmentRequest.contactEmail || "",
            }}
            validationSchema={Yup.object().shape({
              requestingProviderFirstName: Yup.string().required("Required"),
              requestingProviderLastName: Yup.string().required("Required"),
              requestingProviderCredential: Yup.string().required("Required"),
              contactPersonFirstName: Yup.string().required("Required"),
              contactPersonLastName: Yup.string().required("Required"),
              contactPersonLocation: Yup.string().required("Required"),
              contactPhoneNumber: Yup.string().required("Required"),
              contactInstructions: Yup.string(),
            })}
            onSubmit={onSubmit}
          >
            {({ values, status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ padding: "0 1rem 1rem" }}>
                <FormStatusErrors status={status} />

                <div className="mt-4">
                  <RadioGroupField
                    name="whoIsPerformingP2p"
                    label="Who will be performing the P2P consultation?"
                    options={whoIsPerformingP2pOptions}
                    indicateRequired
                  />
                </div>

                <section className="p-4 mt-4 border rounded">
                  <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                    <FAIcon icon="user-md" className="mr-2" />
                    Requesting Provider
                  </h4>
                  <div className="flex mt-4">
                    <div className="flex-1">
                      <VerticalField
                        label="Name of Provider on Case"
                        indicateRequired
                      >
                        <div className="gap-x-1 grid grid-cols-2">
                          <div>
                            <TextInput
                              name="requestingProviderFirstName"
                              placeholder="First Name"
                            />
                          </div>
                          <div>
                            <TextInput
                              name="requestingProviderLastName"
                              placeholder="Last Name"
                            />
                          </div>
                        </div>
                      </VerticalField>
                    </div>
                    <div className="w-36 ml-2">
                      <PlainSelectField
                        name="requestingProviderCredential"
                        label="Credential"
                        options={credentialOptions}
                        indicateRequired
                      />
                    </div>
                  </div>

                  {values.requestingProviderCredential === "Other" ? (
                    <div className="mt-4">
                      <TextField
                        name="otherRequestingProviderCredential"
                        label="Please specify provider credential"
                        indicateRequired
                      />
                    </div>
                  ) : null}
                </section>

                <section className="p-4 mt-4 border rounded">
                  <div className="flex items-center">
                    <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                      <FAIcon icon={["far", "address-card"]} className="mr-2" />
                      Contact Person
                    </h4>

                    <SameAsProviderCheckbox className="ml-4" />
                  </div>

                  <div className="flex mt-4">
                    <div className="grid flex-1 grid-cols-2 gap-3">
                      <div>
                        <TextField
                          name="contactPersonFirstName"
                          label="Contact First Name"
                          indicateRequired
                        />
                      </div>
                      <div>
                        <TextField
                          name="contactPersonLastName"
                          label="Contact Last Name"
                          indicateRequired
                        />
                      </div>
                    </div>
                    {values.whoIsPerformingP2p === "Contact Person" ? (
                      <div className="w-36 ml-2">
                        <PlainSelectField
                          name="contactPersonCredential"
                          label="Credential"
                          options={credentialOptions}
                          indicateRequired
                        />
                      </div>
                    ) : null}
                  </div>

                  {values.whoIsPerformingP2p === "Contact Person" &&
                  values.contactPersonCredential === "Other" ? (
                    <div className="mt-4">
                      <TextField
                        name="otherContactPersonCredential"
                        label="Please specify provider credential"
                        indicateRequired
                      />
                    </div>
                  ) : null}

                  <div className="mt-4">
                    <SelectField
                      name="contactPersonLocation"
                      label="Contact Person Location"
                      options={contactPersonLocationOptions}
                      indicateRequired
                    />
                  </div>
                </section>

                <section className="p-4 mt-4 border rounded">
                  <h4 className="px-5 py-2 text-sm font-semibold text-gray-400 uppercase">
                    <FAIcon icon={"phone"} className="mr-2" />
                    P2P Information
                  </h4>
                  {values.whoIsPerformingP2p === "Other" ? (
                    <>
                      <div className="flex mt-4">
                        <div className="flex-1">
                          <VerticalField
                            label="Name of Person Performing P2P"
                            indicateRequired
                          >
                            <div className="gap-x-1 grid grid-cols-2">
                              <div>
                                <TextInput
                                  name="otherP2pContactFirstName"
                                  placeholder="First Name"
                                />
                              </div>
                              <div>
                                <TextInput
                                  name="otherP2pContactLastName"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </VerticalField>
                        </div>
                        <div className="w-36 ml-2">
                          <PlainSelectField
                            name="otherP2pContactCredential"
                            label="Credential"
                            options={credentialOptions}
                            indicateRequired
                          />
                        </div>
                      </div>
                      {values.otherP2pContactCredential === "Other" ? (
                        <div className="mt-4">
                          <TextField
                            name="otherP2pContactOtherCredential"
                            label="Please specify credential for Person Performing P2P"
                            indicateRequired
                          />
                        </div>
                      ) : null}
                    </>
                  ) : null}

                  <div className="flex mt-4">
                    <div className="flex-1">
                      <PhoneMaskField
                        name="contactPhoneNumber"
                        label="Phone Number for P2P"
                        icon="phone"
                        indicateRequired
                      />
                    </div>

                    <div className="w-36 ml-2">
                      <TextField
                        name="contactPhoneNumberExtension"
                        label="Phone Ext."
                        icon="phone"
                      />
                    </div>
                  </div>

                  <div className="flex mt-4">
                    <div className="flex-1">
                      <PhoneMaskField
                        name="alternateContactPhoneNumber"
                        label="Alternate Phone"
                        icon="phone"
                      />
                    </div>

                    <div className="w-36 ml-2">
                      <TextField
                        name="alternateContactPhoneNumberExtension"
                        label="Phone Ext."
                        icon="phone"
                      />
                    </div>
                  </div>

                  <div className="mt-4">
                    <TextField
                      name="contactEmail"
                      label="Requesting Provider Email"
                    />
                  </div>

                  <div className="mt-4">
                    <TextField
                      name="contactInstructions"
                      label="Contact Instructions"
                    />
                  </div>

                  <div className="mt-4">
                    <VerticalField label="Call Notes">
                      <CallNotesInput name="callNotes" />
                    </VerticalField>
                  </div>
                </section>

                <div className="flex justify-end mt-6">
                  <Button
                    type="submit"
                    kind="primary"
                    color="teal"
                    size="lg"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};

function SameAsProviderCheckbox(props: { className?: string }) {
  const { className = "" } = props;
  const { values, setFieldValue } = useFormikContext<FormValues>();

  const [checked, setChecked] = useState(false);

  function handleChange() {
    const newValue = !checked;
    setChecked(newValue);

    if (newValue) {
      setFieldValue(
        "contactPersonFirstName",
        values.requestingProviderFirstName
      );
      setFieldValue("contactPersonLastName", values.requestingProviderLastName);
      setFieldValue(
        "contactPersonCredential",
        values.requestingProviderCredential
      );
    } else {
      setFieldValue("contactPersonFirstName", "");
      setFieldValue("contactPersonLastName", "");
      setFieldValue("contactPersonCredential", "");
    }
  }

  return values.whoIsPerformingP2p === "Requesting Provider" ? (
    <label className={`checkbox flex items-center text-sm ${className}`}>
      <input
        id="sameAsProvider"
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        style={{ marginRight: "0.5rem" }}
        className="focus:ring-blue-500 w-4 h-4 text-blue-600 border-gray-300 rounded"
      />
      Same as Requesting Provider
    </label>
  ) : null;
}

function validateExtras(values: FormValues): Record<string, string> {
  let extraErrors: Record<string, string> = {};

  if (
    values.requestingProviderCredential === "Other" &&
    values.otherRequestingProviderCredential.trim() === ""
  ) {
    extraErrors.otherRequestingProviderCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential.trim() === ""
  ) {
    extraErrors.contactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Contact Person" &&
    values.contactPersonCredential === "Other" &&
    values.otherContactPersonCredential.trim() === ""
  ) {
    extraErrors.otherContactPersonCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactFirstName.trim() === ""
  ) {
    extraErrors.otherP2pContactFirstName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactLastName.trim() === ""
  ) {
    extraErrors.otherP2pContactLastName = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactCredential = "Required";
  }

  if (
    values.whoIsPerformingP2p === "Other" &&
    values.otherP2pContactCredential === "Other" &&
    values.otherP2pContactOtherCredential.trim() === ""
  ) {
    extraErrors.otherP2pContactOtherCredential = "Required";
  }

  return extraErrors;
}
