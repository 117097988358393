import { FC, useCallback } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { useToast } from "layouts/PortalLayout/Toast";
// import { SelectField } from 'components/formik/SelectField';
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { DateMaskField } from "components/formik/DateMaskField";
import { CheckboxesField } from "components/formik/CheckboxesField";
import { LIST_EXPORTS } from "../../ExportsWidget/ExportsWidget";
import { RadioGroupField } from "components/formik/RadioGroupField";
import { Button } from "components/Button";

const CREATE_EXPORT = gql`
  mutation CreateDataExport($input: CreateDataExportInput!) {
    createDataExport(input: $input) {
      errors {
        key
        message
      }
      export {
        id
        filename
      }
    }
  }
`;

interface MutationData {
  createDataExport: {
    errors?: InputError[];
    export?: {
      id: string;
      filename: string;
    };
  };
}

const exportOptions = [
  { value: "webform_submissions", label: "Webform Submissions" },
];

const encryptionTypeOptions = [
  { value: "AES", label: "AES" },
  { value: "PGP", label: "PGP" },
];

interface FormValues {
  windowStart: string;
  windowFinish: string;
  password: string;
  encryptionType: string;
  exports: string[];
}

const validationSchema = Yup.object()
  .shape({
    password: Yup.string()
      .matches(/^[A-Za-z\d@$!%*#?&]+$/, {
        message: "Can only contain letters, numbers or @$!%*#?&",
        excludeEmptyString: true,
      })
      .min(8)
      .required("Required"),
    encryptionType: Yup.string()
      .oneOf(["AES", "PGP"], "Invalid selection")
      .required("Required"),
    exports: Yup.array()
      .min(1, "Must select at least 1")
      .required("Required"),
  })
  .required();

interface WebformSubmissionVolumeExportFormProps {
  initialValues: {
    windowStart: string;
    windowFinish: string;
  };
  onSuccess(): void;
}

export const WebformSubmissionVolumeExportForm: FC<WebformSubmissionVolumeExportFormProps> = (
  props
) => {
  const { onSuccess, initialValues } = props;
  const [createExport] = useMutation<MutationData>(CREATE_EXPORT);

  const toast = useToast();

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await createExport({
          variables: { input: values },
          refetchQueries: [{ query: LIST_EXPORTS }],
        });

        if (data?.createDataExport.errors) {
          setStatus({ errors: data.createDataExport.errors });
        } else if (data?.createDataExport.export) {
          // it worked...
          toast.success("Success!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        setStatus({
          errors: [{ key: "", message: "Something went wrong." }],
        });
      } finally {
        setSubmitting(false);
      }
    },
    [createExport, onSuccess, toast]
  );

  return (
    <Formik<FormValues>
      initialValues={{
        windowStart: initialValues.windowStart,
        windowFinish: initialValues.windowFinish,
        password: "",
        encryptionType: "AES",
        exports: ["webform_submissions"],
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormStatusErrors status={status} />
          <div className="gap-x-3 grid grid-cols-2">
            <DateMaskField
              name="windowStart"
              label="Window Start"
              minYear={2010}
              maxYear={2040}
            />

            <DateMaskField
              name="windowFinish"
              label="Window End"
              minYear={2010}
              maxYear={2040}
            />
          </div>

          <div className="mt-3">
            <TextField
              type="password"
              name="password"
              placeholder="••••••••••"
              label="Password for file encryption"
              icon="lock"
              autoFocus
            />
          </div>

          <div className="mt-3">
            <RadioGroupField
              name="encryptionType"
              label="Encryption Type"
              options={encryptionTypeOptions}
            />
          </div>

          <div className="mt-3">
            <CheckboxesField
              name="exports"
              label="Export Files"
              options={exportOptions}
            />
          </div>
          <div style={{ margin: "1.5rem auto 1rem", textAlign: "center" }}>
            <Button
              type="submit"
              kind="primary"
              color="teal"
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              Export
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};
