import React, { FC, useState } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import {
  FETCH_CASE_PROGRAM,
  CaseProgram,
  CaseProgramSkillPrioritization,
  Skill,
} from "./EditCaseProgramModal";
import { FAIcon } from "components/FAIcon";
import { HorizontalSelectField } from "components/formik/SelectField";
import { HorizontalNumberField } from "components/formik/NumberField";
import { Button } from "components/Button";

const FETCH_SKILLS = gql`
  query GetSkills {
    skills {
      id
      name
      externalSystemName
    }
  }
`;

interface Data {
  skills: Skill[];
}

const CREATE_SKILL_PRIORITIZATION = gql`
  mutation CreateCaseProgramSkillPrioritization(
    $input: CaseProgramSkillPrioritizationInput!
  ) {
    createCaseProgramSkillPrioritization(input: $input) {
      errors {
        key
        message
      }
      caseProgramSkillPrioritization {
        id
        priority
        skill {
          id
          name
          externalSystemName
        }
      }
    }
  }
`;

interface CreateData {
  createCaseProgramSkillPrioritization: {
    errors?: InputError[];
    caseProgramSkillPrioritization?: {
      id: string;
      priority: number;
      skill: Skill;
    };
  };
}

/**
 * CreateSkillPrioritizationForm.
 */

interface CreateSkillPrioritizationFormProps {
  caseProgram: CaseProgram;
}

const CreateSkillPrioritizationForm: FC<CreateSkillPrioritizationFormProps> = ({
  caseProgram,
}) => {
  const [isCreating, setCreating] = useState(false);
  const toast = useToast();

  const { data, loading } = useQuery<Data>(FETCH_SKILLS);
  const [create] = useMutation<CreateData>(CREATE_SKILL_PRIORITIZATION);

  if (!isCreating) {
    return (
      <div>
        <Button
          type="button"
          kind="tertiary"
          size="xs"
          color="blue"
          onClick={() => setCreating(true)}
        >
          Add Skill Prioritization...
        </Button>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        priority: 1,
        skillId: "",
      }}
      validationSchema={Yup.object().shape({
        priority: Yup.number().required("Required"),
        skillId: Yup.string().required("Required"),
      })}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus({ errors: null });
        create({
          variables: {
            input: { caseProgramId: caseProgram.id, ...values },
          },
          refetchQueries: [
            {
              query: FETCH_CASE_PROGRAM,
              variables: { id: caseProgram.id },
            },
          ],
        }).then((resp) => {
          if (resp?.data?.createCaseProgramSkillPrioritization.errors) {
            setStatus({
              errors: resp.data.createCaseProgramSkillPrioritization.errors,
            });
          } else if (
            resp?.data?.createCaseProgramSkillPrioritization
              .caseProgramSkillPrioritization
          ) {
            toast.success(`Successfully created skill prioritization!`);
            setCreating(false);
          }
          setSubmitting(false);
        });
      }}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="CreateModalityForm">
          <FormStatusErrors status={status} />
          <HorizontalSelectField
            name="skillId"
            label="Skill"
            isLoading={loading}
            options={((data && data.skills) || []).map((s) => ({
              value: s.id,
              label: `${s.name} (${s.externalSystemName})`,
            }))}
          />
          <HorizontalNumberField name="priority" label="Priority" />

          <div className="gap-x-2 flex justify-center mt-2">
            <Button
              type="button"
              kind="secondary"
              color="gray"
              size="sm"
              onClick={() => setCreating(false)}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              kind="primary"
              color="teal"
              size="sm"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

const DELETE_SKILL_PRIORITIZATION = gql`
  mutation DeleteCaseProgramSkillPrioritization($id: UUID4!) {
    deleteCaseProgramSkillPrioritization(id: $id) {
      errors {
        key
        message
      }
    }
  }
`;

interface DeleteData {
  deleteCaseProgramSkillPrioritization: {
    errors?: InputError[];
  };
}

/**
 * CaseProgramSkillPrioritizationRow.
 */

interface CaseProgramSkillPrioritizationRowProps {
  caseProgram: CaseProgram;
  caseProgramSkillPrioritization: CaseProgramSkillPrioritization;
}

const CaseProgramSkillPrioritizationRow: FC<CaseProgramSkillPrioritizationRowProps> = (
  props
) => {
  const { caseProgram, caseProgramSkillPrioritization: cpsp } = props;

  const toast = useToast();
  const [deleteSkillPrioritization, { loading }] = useMutation<DeleteData>(
    DELETE_SKILL_PRIORITIZATION
  );

  return (
    <div className="gap-x-1 flex items-center p-1 mb-1 border rounded-lg">
      <p className="px-2 mr-2 text-xl font-semibold">{cpsp.priority}</p>
      <p className="flex-grow text-center">
        {cpsp.skill.name} ({cpsp.skill.externalSystemName})
      </p>
      <Button
        type="button"
        className="border"
        kind="tertiary"
        size="sm"
        color="red"
        disabled={loading}
        isLoading={loading}
        onClick={() => {
          deleteSkillPrioritization({
            variables: { id: cpsp.id },
            refetchQueries: [
              {
                query: FETCH_CASE_PROGRAM,
                variables: { id: caseProgram.id },
              },
            ],
          }).then((resp) => {
            if (resp?.data?.deleteCaseProgramSkillPrioritization.errors) {
              toast.warning("Couldn't delete Skill Priority");
            } else {
              toast.success(
                `Skill prioritization for "${cpsp.skill.name}" successfully deleted!`
              );
            }
          });
        }}
      >
        <FAIcon icon="trash-alt" />
      </Button>
    </div>
  );
};

/**
 * EditSkillPrioritizations.
 */

interface EditSkillPrioritizationsProps {
  caseProgram: CaseProgram;
}

export const EditSkillPrioritizations: FC<EditSkillPrioritizationsProps> = (
  props
) => {
  const { caseProgram } = props;
  return (
    <div className="EditSkillPrioritizations">
      {caseProgram.caseProgramSkillPrioritizations.length > 0 ? (
        caseProgram.caseProgramSkillPrioritizations
          .sort(sorter)
          .map((cpsp) => (
            <CaseProgramSkillPrioritizationRow
              key={cpsp.id}
              caseProgram={caseProgram}
              caseProgramSkillPrioritization={cpsp}
            />
          ))
      ) : (
        <p className="p-2 py-4 mb-2 text-sm font-medium text-center text-gray-700 bg-gray-100 rounded-lg">
          No Skill Prioritizations
        </p>
      )}
      <CreateSkillPrioritizationForm caseProgram={caseProgram} />
    </div>
  );
};

// Ascending order (1, 2, 3, ...)
const sorter = (
  a: CaseProgramSkillPrioritization,
  b: CaseProgramSkillPrioritization
) => (a.priority > b.priority ? 1 : -1);
