import { FC } from "react";
import "./BlockSpinner.css";

interface BlockSpinnerProps {}

export const BlockSpinner: FC<BlockSpinnerProps> = () => {
  return (
    <div
      className="BlockSpinner"
      role="progressbar"
      aria-busy={true}
      aria-label="Loading"
    />
  );
};
