import { FC, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "components/Modal";
import { PillTab, PillTabs } from "components/PillTabs";
import { CaseProfileForm } from "./CaseProfileForm";
import { CaseLookupForm } from "./CaseLookupForm";
import { CaseLookupModal } from "./CaseLookupModal";
import logoSrc from "images/evicore_logo_full.png";
import "./NewAppointmentRequestPage.css";

export interface CaseLookupParams {
  lineOfBusiness: string;
  healthPlanId: string;
  caseReferenceNumber: string;
  memberDob: string;
}

/**
 * NewAppointmentRequestPage.
 */

type ActiveTab = "MANUAL" | "LOOKUP";

interface NARPProps {}

export const NewAppointmentRequestPage: FC<NARPProps> = () => {
  const history = useHistory();
  const [caseLookups, setCaseLookups] = useState<null | CaseLookupParams[]>(
    null
  );
  const [activeTab, setActiveTab] = useState<ActiveTab>("LOOKUP");

  // NB: This is a little hacky but I don't want to move the form state up to this parent component right now
  const caseLookupsForManualTab = useRef<CaseLookupParams[] | null>(null);

  // Track the latest caseLookups value in the manualTabInitialValues ref
  if (caseLookups) {
    caseLookupsForManualTab.current = caseLookups;
  }

  return (
    <div>
      {process.env.REACT_APP_CASE_LOOKUP_DISABLED !== "true" && (
        <div className="flex justify-center">
          <PillTabs
            activeTab={activeTab}
            onChange={setActiveTab as (tab: string) => void}
          >
            <PillTab tab="MANUAL">Manual Input</PillTab>
            <PillTab tab="LOOKUP">Case Lookup</PillTab>
          </PillTabs>
        </div>
      )}
      <div className="p-4 mt-4 text-left bg-white rounded-lg shadow-lg">
        <Modal
          isOpen={caseLookups !== null}
          onRequestClose={() => setCaseLookups(null)}
          className="max-w-4xl"
        >
          {caseLookups !== null ? (
            <CaseLookupModal
              caseLookups={caseLookups}
              onClose={() => setCaseLookups(null)}
              onCreate={(appointmentRequestId) => {
                setCaseLookups(null);
                return history.push(
                  `/o/appointment_request_wizard/2?appointmentRequestId=${appointmentRequestId}`
                );
              }}
            />
          ) : null}
        </Modal>
        <div className="px-6 pt-6 pb-12">
          <div className="StepHeaderLogo">
            <img
              src={logoSrc}
              alt="Step Header"
              className="max-h-100 max-w-100 object-contain"
            />
          </div>
          <div className="mt-6">
            <h1 className="text-3xl font-bold leading-relaxed">
              New P2P Request
            </h1>
            <h2 className="text-xl">Complete form to initiate a P2P Request</h2>

            <div className="mt-6">
              {activeTab === "LOOKUP" ? (
                <div className="max-w-2xl mx-auto mt-6">
                  <CaseLookupForm
                    onSubmit={(caseLookups) => setCaseLookups(caseLookups)}
                  />
                </div>
              ) : (
                <div className="w-full">
                  <CaseProfileForm
                    key={
                      JSON.stringify(caseLookupsForManualTab.current) ||
                      "activeTab"
                    }
                    caseLookups={caseLookupsForManualTab.current}
                    onCreate={(appointmentRequestId) =>
                      history.push(
                        `/o/appointment_request_wizard/2?appointmentRequestId=${appointmentRequestId}`
                      )
                    }
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
