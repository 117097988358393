import { FC } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Transition } from "react-spring";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { validPassword } from "lib/validPassword";
import "./ResetPasswordForm.css";
import { TextField } from "components/formik/TextField";
import { Button } from "components/Button";

const RESET_PASSWORD = gql`
  mutation(
    $id: UUID4!
    $token: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    resetPassword(
      id: $id
      token: $token
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      errors {
        key
        message
      }
      session {
        token
      }
    }
  }
`;

interface MutationData {
  resetPassword: {
    errors?: InputError[];
    session?: {
      token: string;
    };
  };
}

const items = [{ key: "1" }];

interface PasswordResetFormProps {
  id: string;
  token: string;
  onSignIn(jwt: string): any;
}

export const ResetPasswordForm: FC<PasswordResetFormProps> = (props) => {
  const { id, token, onSignIn } = props;
  const [resetPassword] = useMutation<MutationData>(RESET_PASSWORD);

  return (
    <Transition
      items={items}
      from={{ opacity: 0 }}
      enter={{ opacity: 1 }}
      leave={{ opacity: 0 }}
    >
      {(item) =>
        item &&
        ((styles) => (
          <div style={styles} className="ResetPasswordForm_container">
            <Formik
              initialValues={{ password: "", passwordConfirmation: "" }}
              validationSchema={Yup.object().shape({
                password: validPassword,
                passwordConfirmation: validPassword,
              })}
              onSubmit={(
                { password, passwordConfirmation },
                { setStatus, setSubmitting }
              ) => {
                setStatus({ errors: null });
                resetPassword({
                  variables: { id, token, password, passwordConfirmation },
                }).then((resp) => {
                  if (resp?.data?.resetPassword.errors) {
                    setStatus({ errors: resp.data.resetPassword.errors });
                  } else if (resp?.data?.resetPassword.session) {
                    const { token } = resp.data.resetPassword.session;

                    onSignIn(token);
                  }
                  setSubmitting(false);
                });
              }}
            >
              {({ status, isSubmitting, handleSubmit }) => (
                <article
                  className="card mt-8"
                  style={{ borderRadius: "0.5rem" }}
                >
                  <div className="card-content">
                    <h4 className="ResetPasswordForm__title text-lg font-semibold">
                      Reset Your Password
                    </h4>
                    <form onSubmit={handleSubmit} className="text-left">
                      <FormStatusErrors status={status} />

                      <TextField
                        label="New Password"
                        type="password"
                        placeholder="••••••••••"
                        name="password"
                        icon="lock"
                      />

                      <div className="mt-3">
                        <TextField
                          label="Confirm New Password"
                          type="password"
                          placeholder="••••••••••"
                          name="passwordConfirmation"
                          icon="lock"
                        />
                      </div>

                      <div className="mt-6 text-center">
                        <Button
                          type="submit"
                          kind="primary"
                          color="teal"
                          disabled={isSubmitting}
                          isLoading={isSubmitting}
                        >
                          Reset Password
                        </Button>
                      </div>
                    </form>
                  </div>
                </article>
              )}
            </Formik>
            <div className="ResetPasswordForm__links-wrapper has-text-weight-bold">
              <Link to="/auth/sign_in">Sign In</Link>
              {/* <Link to="/auth/register">Register</Link> */}
            </div>
          </div>
        ))
      }
    </Transition>
  );
};
