import { FC } from "react";
import { useLocation, Link } from "react-router-dom";
import { afterSignInPath } from "components/AuthRoutes";
import evicoreLogoSrc from "images/evicore_logo_full.png";

export const SignInPage: FC = () => {
  const location = useLocation();
  const targetUrl = afterSignInPath(location);
  const encodedTargetUrl = encodeURIComponent(targetUrl);

  return (
    <div className="_SignInPage border-mint-500 p-4 bg-white border-t-8 rounded-lg shadow-lg">
      <div className="gap-x-4 md:flex">
        <div className="flex items-center justify-center flex-grow p-4">
          <img className="w-48 max-w-xs" src={evicoreLogoSrc} alt="logo" />
        </div>
        <div className="flex-grow">
          <section className="py-12">
            <div className="content text-left text-teal-500">
              <h1
                style={{ fontSize: 64 }}
                className="accent-green-border-btm font-semibold"
              >
                P2P Portal
              </h1>
              <p className="mt-6" style={{ fontSize: 20, lineHeight: 1.55 }}>
                Click below to sign in to the EviCore P2P portal.
              </p>
            </div>
            {/* <a
              href={`/sso/auth/signin/cigna?target_url=${encodedTargetUrl}`}
              className="hover:bg-mint-500 focus:border-mint-700 focus:shadow-outline-mint active:bg-mint-700 bg-mint-600 inline-block px-8 py-4 m-8 text-2xl font-bold leading-6 text-teal-900 uppercase border-transparent rounded-full"
            > */}
            <a
              href={`/sso/auth/signin/cigna?target_url=${encodedTargetUrl}`}
              className="hover:bg-teal-500 focus:border-teal-700 focus:shadow-outline-teal active:bg-teal-700 inline-block px-8 py-4 m-8 text-xl font-bold leading-6 text-white uppercase bg-teal-600 border-transparent rounded-full"
            >
              Sign in
            </a>
          </section>
          <section>
            {/* <p className="mb-3 text-xs">
              Or,{" "}
              <a
                className="link"
                href={`/sso/auth/signin/evicore?target_url=${encodedTargetUrl}`}
              >
                Sign in with PingFed SSO
              </a>
            </p> */}
            <p className="text-xs">
              Or,{" "}
              <Link className="link" to="/auth/old_sign_in">
                Sign in with Email
              </Link>
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};
