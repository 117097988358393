import React, { FC, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { SelectField } from "components/formik/SelectField";
import { Spinner } from "components/Spinner";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { Modal, ModalHeader } from "components/Modal";
import { Button } from "components/Button";

const APPOINTMENT_REQUEST_QUERY = gql`
  query AppointmentRequestInformation($appointmentRequestId: UUID4!) {
    appointmentRequest(id: $appointmentRequestId) {
      id
      caseProfiles {
        id
        levelOfReview {
          value
          label
        }
      }
    }
    reviewLevels {
      value
      label
    }
  }
`;

interface Data {
  appointmentRequest: {
    id: string;
    caseProfiles: {
      id: string;
      levelOfReview: {
        value: string;
        label: string;
      };
    }[];
  };
  reviewLevels: Option[];
}

interface Variables {
  appointmentRequestId: string;
}

const UPDATE_LEVEL_OF_REVIEW = gql`
  mutation UpdateLevelOfReview(
    $appointmentRequestId: UUID4!
    $levelOfReview: String!
  ) {
    updateAppointmentRequestLevelOfReview(
      id: $appointmentRequestId
      levelOfReview: $levelOfReview
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        caseProfiles {
          id
          levelOfReview {
            value
            label
          }
        }
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestLevelOfReview: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      caseProfiles: {
        id: string;
        levelOfReview: {
          value: string;
          label: string;
        };
      }[];
    };
  };
}

interface MutationVariables {
  appointmentRequestId: string;
  levelOfReview: string;
}

interface FormValues {
  levelOfReview: string;
}

const validationSchema = Yup.object()
  .shape({
    levelOfReview: Yup.string().required("Required"),
  })
  .required();

interface UpdateRequestInformationModalProps {
  appointmentRequestId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const UpdateRequestInformationModal: FC<UpdateRequestInformationModalProps> = (
  props
) => {
  const { appointmentRequestId, isOpen, onClose, onSuccess } = props;

  const { data, loading, error } = useQuery<Data, Variables>(
    APPOINTMENT_REQUEST_QUERY,
    { variables: { appointmentRequestId } }
  );
  const [updateLevelOfReview] = useMutation<MutationData, MutationVariables>(
    UPDATE_LEVEL_OF_REVIEW
  );

  const toast = useToast();

  const onSubmit = useCallback(
    async (values: FormValues, formikHelpers) => {
      const { setStatus, setSubmitting } = formikHelpers;

      // NB: "post_review_consult" is an alias of "reconsideration"
      const variables: MutationVariables = {
        appointmentRequestId,
        levelOfReview:
          values.levelOfReview === "post_review_consult"
            ? "reconsideration"
            : values.levelOfReview,
      };

      try {
        const { data } = await updateLevelOfReview({ variables });

        if (data?.updateAppointmentRequestLevelOfReview.errors) {
          setStatus({
            errors: data.updateAppointmentRequestLevelOfReview.errors,
          });
        } else if (
          data?.updateAppointmentRequestLevelOfReview.appointmentRequest
        ) {
          // it worked...
          toast.success("Level of Review updated!");
          return onSuccess();
        }
        setSubmitting(false);
      } catch (e) {
        toast.danger("Something went wrong.");
        console.error(e);
      } finally {
        setSubmitting(false);
      }
    },
    [toast, updateLevelOfReview, appointmentRequestId, onSuccess]
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="_UpdateRequestInformationModal"
    >
      <div className="bg-white rounded-lg shadow-lg">
        <ModalHeader
          icon={["far", "address-card"]}
          title="Update Request Information"
          onClose={onClose}
        />
        {loading ? (
          <Spinner />
        ) : error || !data?.appointmentRequest ? (
          <p>Failed to load.</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              levelOfReview:
                data.appointmentRequest.caseProfiles[0].levelOfReview.value,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit} style={{ padding: "0 1rem 1rem" }}>
                <FormStatusErrors status={status} />

                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
                <SelectField
                  name="levelOfReview"
                  label="Level of Review"
                  options={data.reviewLevels}
                />

                <div className="flex justify-end mt-6">
                  <Button
                    type="submit"
                    kind="primary"
                    color="teal"
                    size="lg"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
