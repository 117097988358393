import { FC } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { FETCH_CASE_PROGRAM, CaseProgram } from "./EditCaseProgramModal";
import { Button } from "components/Button";

const UPDATE_CASE_PROGRAM = gql`
  mutation UpdateCaseProgram($id: UUID4!, $input: CaseProgramInput!) {
    updateCaseProgram(id: $id, input: $input) {
      errors {
        key
        message
      }
      caseProgram {
        id
      }
    }
  }
`;

interface MutationData {
  updateCaseProgram: {
    errors?: InputError[];
    caseProgram?: {
      id: string;
    };
  };
}

interface EditCaseProgramFormProps {
  caseProgram: CaseProgram;
  onClose(): void;
}

export const EditCaseProgramForm: FC<EditCaseProgramFormProps> = (props) => {
  const { caseProgram, onClose } = props;

  const [updateCaseProgram] = useMutation<MutationData>(UPDATE_CASE_PROGRAM);
  const toast = useToast();

  return (
    <div className="EditCaseProgramForm">
      <Formik
        initialValues={{ name: caseProgram.name }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          updateCaseProgram({
            variables: { id: caseProgram.id, input: values },
            refetchQueries: [
              {
                query: FETCH_CASE_PROGRAM,
                variables: { id: caseProgram.id },
              },
            ],
          }).then((resp) => {
            if (resp?.data?.updateCaseProgram.errors) {
              setStatus({ errors: resp.data.updateCaseProgram.errors });
            } else {
              toast.success(`Update Case Program - ${values.name}`);
              onClose();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <TextField name="name" label="Name" />
            <div className="mt-3">
              <Button
                type="submit"
                kind="primary"
                color="teal"
                size="sm"
                disabled={isSubmitting}
                isLoading={isSubmitting}
              >
                Update
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
