import React from "react";
import { Switch, Route, RouteComponentProps } from "react-router-dom";
import { UserDropdown } from "./UserDropdown";
import { SidebarLink } from "./SidebarLink";
import { AnalyticsPage } from "pages/AnalyticsPage";
import { WorklistPage } from "pages/WorklistPage";
import { SettingsPage } from "pages/SettingsPage";
import { WebformSubmissionsPage } from "pages/WebformSubmissionsPage";
import { AppointmentRequestWizard } from "layouts/AppointmentRequestWizard";
import { AppointmentRequestShowPage } from "pages/AppointmentRequestShowPage";
import { NavTitleProvider, NavTitleDisplay } from "./NavTitle";
import { ToastProvider, ToastDisplay } from "./Toast";
import { EmailConfirmationBanner } from "./EmailConfirmationBanner";
import logoSrc from "images/evicore_logo_full.png";
import "./PortalLayout.css";
import gql from "graphql-tag";
import { useQuery } from "react-apollo";
import { Spinner } from "components/Spinner";
import { FAIcon } from "components/FAIcon";
import { DataExportDownloadPage } from "pages/DataExportDownloadPage";

const CURRENT_USER = gql`
  query CurrentUserWithRole {
    me {
      id
      role
      availableServices
    }
    aaasHost
  }
`;

interface Data {
  me: {
    id: string;
    role: "admin" | "member" | "restricted_member";
    availableServices: string[];
  };
  aaasHost: string;
}

type PortalLayoutProps = {
  signOut: () => void;
} & RouteComponentProps;

const PortalLayout: React.SFC<PortalLayoutProps> = ({ signOut, match }) => {
  const { data, loading, error } = useQuery<Data>(CURRENT_USER);

  const role = data?.me.role;
  const showAllLinks = (["member", "admin"] as Maybe<string>[]).includes(role);

  return (
    <NavTitleProvider>
      <ToastProvider>
        {loading ? (
          <div>
            <Spinner />
          </div>
        ) : error || !data?.me ? (
          <p>Failed to load</p>
        ) : (
          <div className="PortalLayout__grid">
            <div className="PortalLayout__nav">
              <EmailConfirmationBanner />
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <div className="PortalLayout__logo-container">
                    <img
                      src={logoSrc}
                      className="object-contain max-w-full max-h-full px-5 py-2"
                      alt="EviCore logo"
                    />
                  </div>
                  {/* <div className="PortalLayout__hamburger-container">
                      <FontAwesomeIcon icon="bars" />
                    </div> */}
                  <div className="PortalLayout__page-title-container">
                    <h1 className="PortalLayout__page-title">
                      <NavTitleDisplay />
                    </h1>
                  </div>
                </div>
                <div className="flex mx-6 -my-px space-x-8">
                  {(data.me.availableServices || []).includes("aaas") ? (
                    <a
                      href={`${data.aaasHost}/auth/evicore`}
                      className="hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-300 inline-flex items-center px-1 pt-1 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out border-b-2 border-transparent"
                      target="_blank"
                      rel="noref noreferrer nofollow"
                    >
                      Appeals{" "}
                      <FAIcon icon="external-link-alt" className="ml-2" />
                    </a>
                  ) : null}
                  <UserDropdown signOut={signOut} />
                </div>
              </div>
            </div>
            <div className="PortalLayout__sidebar bg-teal-500">
              <ul>
                <SidebarLink
                  icon="plus"
                  path={`${match.path}appointment_request_wizard`}
                  label="New Request"
                />
                {/* <SidebarLink
                    icon="calendar-alt"
                    path={`${match.path}calendar`}
                    label="Calendar"
                  /> */}
                {showAllLinks ? (
                  <>
                    <SidebarLink
                      icon="inbox"
                      path={`${match.path}requests`}
                      label="P2P Requests"
                    />
                    <SidebarLink
                      icon="envelope-open-text"
                      path={`${match.path}webform_submissions`}
                      label="Webform Submissions"
                    />
                    {role === "admin" ? (
                      <SidebarLink
                        icon="chart-bar"
                        path={`${match.path}analytics`}
                        label="Analytics"
                      />
                    ) : null}
                    <SidebarLink
                      icon="cog"
                      path={`${match.path}settings`}
                      label="Settings"
                    />
                  </>
                ) : null}
              </ul>
            </div>
            <ToastDisplay />
            <div className="PortalLayout__content-container">
              <Switch>
                <Route
                  path={`${match.path}appointment_request_wizard`}
                  component={AppointmentRequestWizard}
                />
                <Route
                  path={`${match.path}analytics`}
                  component={AnalyticsPage}
                />
                <Route
                  path={`${match.path}data_exports/:id/download`}
                  component={DataExportDownloadPage}
                />
                <Route
                  exact
                  path={`${match.path}requests`}
                  component={WorklistPage}
                />
                <Route
                  exact
                  path={`${match.path}webform_submissions`}
                  component={WebformSubmissionsPage}
                />
                <Route
                  path={`${match.path}requests/:id`}
                  component={AppointmentRequestShowPage}
                />
                <Route
                  path={`${match.path}settings`}
                  component={SettingsPage}
                />
              </Switch>
            </div>
          </div>
        )}
      </ToastProvider>
    </NavTitleProvider>
  );
};

export { PortalLayout };
