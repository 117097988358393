import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { HorizontalSelectField } from "components/formik/SelectField";
import { PreferredDaysField } from "components/formik/PreferredDaysField";
import { PreferredTimesField } from "components/formik/PreferredTimesField";
import { defaultDays } from "components/PreferredDays";
import { flatDefaultTimes } from "components/PreferredTimes";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";

const days = defaultDays;

const allTimes = flatDefaultTimes;

const AVAILABILITY_FORM_QUERY = gql`
  query TimeZoneNames {
    timeZoneNames
  }
`;

interface Data {
  timeZoneNames: string[];
}

const UPDATE_TIME_PREFERENCE = gql`
  mutation UpdateTimePreference(
    $appointmentRequestId: UUID4!
    $timePreference: TimePreferenceInput!
  ) {
    updateAppointmentRequestTimePreference(
      id: $appointmentRequestId
      timePreference: $timePreference
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        timePreference {
          id
          preferredDays
          preferredTimes
          timeZoneName
        }
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestTimePreference: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      timePreference: {
        id: string;
        preferredDays: string[];
        preferredTimes: string[];
        timeZoneName: string;
      };
    };
  };
}

interface FormValues {
  preferredDays: string[];
  preferredTimes: string[];
  timeZoneName: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    preferredDays: Yup.array()
      .required("Required")
      .min(1, "You must select at least 1 day")
      .ensure()
      .of(Yup.mixed().oneOf(days)),
    preferredTimes: Yup.array()
      .required("Required")
      .min(1, "You must select at least 1 time range")
      .ensure()
      .of(Yup.mixed().oneOf(allTimes)),
    timeZoneName: Yup.string().required("Required"),
  })
  .required();

interface AvailabilityFormProps {
  appointmentRequestId: string;
  initialValues?: Partial<FormValues>;
}

export const AvailabilityForm: FC<AvailabilityFormProps> = (props) => {
  const { appointmentRequestId, initialValues = {} } = props;
  const history = useHistory();

  const { data, loading, error } = useQuery<Data>(AVAILABILITY_FORM_QUERY);
  const [updateTimePreference] = useMutation<MutationData>(
    UPDATE_TIME_PREFERENCE
  );

  return (
    <div className="_AvailabilityForm">
      {loading ? (
        <div className="p-6 text-center">
          <Spinner />
        </div>
      ) : error || !(data && data.timeZoneNames) ? (
        <div style={{ padding: "1.5rem" }}>
          <h1>Failed to Load</h1>
        </div>
      ) : (
        <Formik<FormValues>
          initialValues={{
            preferredDays: days,
            preferredTimes: allTimes,
            timeZoneName: "",
            ...initialValues,
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            setStatus({ errors: null });
            const variables = {
              appointmentRequestId,
              timePreference: values,
            };
            updateTimePreference({
              variables,
            }).then(
              (resp) => {
                if (resp?.data?.updateAppointmentRequestTimePreference.errors) {
                  setStatus({
                    errors:
                      resp.data.updateAppointmentRequestTimePreference.errors,
                  });
                } else if (
                  resp?.data?.updateAppointmentRequestTimePreference
                    .appointmentRequest
                ) {
                  // it worked...
                  return history.push(
                    `/o/appointment_request_wizard/3?appointmentRequestId=${resp.data.updateAppointmentRequestTimePreference.appointmentRequest.id}`
                  );
                }
                setSubmitting(false);
              },
              (err) => {
                console.error(err);
                setStatus({
                  errors: [{ key: "", message: "Something went wrong." }],
                });
                setSubmitting(false);
              }
            );
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form onSubmit={handleSubmit}>
              <PreferredDaysField
                name="preferredDays"
                label="Preferred Days"
                showInstructions
              />

              <div className="mt-6">
                <PreferredTimesField
                  name="preferredTimes"
                  label="Preferred Times"
                  showInstructions
                />
              </div>

              <div className="mt-6">
                <HorizontalSelectField
                  name="timeZoneName"
                  label="Time Zone"
                  options={data.timeZoneNames.map((tz: string) => ({
                    value: tz,
                    label: tz,
                  }))}
                />
              </div>

              <div className="flex justify-end mt-6">
                <Button
                  type="submit"
                  kind="primary"
                  color="teal"
                  size="lg"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Continue
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
};
