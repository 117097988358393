import React, { FC, useState } from "react";
import { useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextField } from "components/formik/TextField";
import {
  FETCH_CASE_PROGRAM,
  CaseProgram,
  Modality,
} from "./EditCaseProgramModal";
import { FAIcon } from "components/FAIcon";
import { Button } from "components/Button";

const CREATE_MODALITY = gql`
  mutation CreateModalityForCaseProgram(
    $caseProgramId: UUID4!
    $input: ModalityInput!
  ) {
    createModality(caseProgramId: $caseProgramId, input: $input) {
      errors {
        key
        message
      }
      modality {
        id
        name
      }
    }
  }
`;

interface CreateData {
  createModality: {
    errors?: InputError[];
    modality?: {
      id: string;
      name: string;
    };
  };
}

interface FormValues {
  id?: string;
  name: string;
}

interface ModalityFormProps {
  onSubmit(values: FormValues, formikHelpers: FormikHelpers<FormValues>): void;
  onCancel(): void;
  initialValues: FormValues;
}

const ModalityForm: FC<ModalityFormProps> = (props) => {
  const { initialValues, onSubmit, onCancel } = props;
  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={Yup.object().shape({
        name: Yup.string().required("Required"),
      })}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <form onSubmit={handleSubmit} className="CreateModalityForm">
          <FormStatusErrors status={status} />
          <div className="gap-x-1 flex items-center mb-2">
            <div className="flex-grow">
              <HorizontalTextField name="name" label="Name" />
            </div>
            <Button
              type="submit"
              kind="primary"
              size="sm"
              color="teal"
              disabled={isSubmitting}
              isLoading={isSubmitting}
            >
              Save
            </Button>
          </div>
          <div className="text-center">
            <Button
              type="button"
              kind="secondary"
              color="gray"
              size="xs"
              onClick={onCancel}
            >
              Cancel Edit
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

interface CreateModalityFormProps {
  caseProgram: CaseProgram;
}

const CreateModalityForm: FC<CreateModalityFormProps> = (props) => {
  const { caseProgram } = props;
  const [isCreating, setCreating] = useState(false);

  const toast = useToast();
  const [createModality] = useMutation<CreateData>(CREATE_MODALITY);

  if (!isCreating) {
    return (
      <div>
        <Button
          type="button"
          kind="tertiary"
          size="xs"
          color="blue"
          onClick={() => setCreating(true)}
        >
          Add Modality...
        </Button>
      </div>
    );
  }
  return (
    <ModalityForm
      initialValues={{ name: "" }}
      onCancel={() => setCreating(false)}
      onSubmit={(values, { setStatus, setSubmitting }) => {
        setStatus({ errors: null });
        createModality({
          variables: { caseProgramId: caseProgram.id, input: values },
          refetchQueries: [
            {
              query: FETCH_CASE_PROGRAM,
              variables: { id: caseProgram.id },
            },
          ],
        }).then((resp) => {
          if (resp?.data?.createModality.errors) {
            setStatus({ errors: resp?.data?.createModality.errors });
          } else {
            toast.success("Created Modality!");
            setCreating(false);
          }
          setSubmitting(false);
        });
      }}
    />
  );
};

const UPDATE_MODALITY = gql`
  mutation UpdateModality($id: UUID4!, $input: ModalityInput!) {
    updateModality(id: $id, input: $input) {
      errors {
        key
        message
      }
      modality {
        id
        name
      }
    }
  }
`;

interface UpdateData {
  updateModality: {
    errors?: InputError[];
    modality?: {
      id: string;
      name: string;
    };
  };
}

const DELETE_MODALITY = gql`
  mutation DeleteModality($id: UUID4!) {
    deleteModality(id: $id) {
      errors {
        key
        message
      }
    }
  }
`;

interface DeleteData {
  deleteModality: {
    errors?: InputError[];
  };
}

/**
 * ModalityRow.
 */

interface ModalityRowProps {
  caseProgram: CaseProgram;
  modality: Modality;
}

const ModalityRow: FC<ModalityRowProps> = (props) => {
  const { caseProgram, modality } = props;
  const [isEditing, setEditing] = useState(false);

  const toast = useToast();
  const [updateModality] = useMutation<UpdateData>(UPDATE_MODALITY);
  const [deleteModality, { loading }] = useMutation<DeleteData>(
    DELETE_MODALITY
  );

  if (isEditing) {
    return (
      <ModalityForm
        initialValues={{ id: modality.id, name: modality.name }}
        onCancel={() => setEditing(false)}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          updateModality({
            variables: { id: modality.id, input: values },
            refetchQueries: [
              {
                query: FETCH_CASE_PROGRAM,
                variables: { id: caseProgram.id },
              },
            ],
          }).then((resp) => {
            if (resp && resp.data) {
              if (resp.data.updateModality.errors) {
                setStatus({ errors: resp.data.updateModality.errors });
              } else {
                toast.success("Updated Modality!");
                setEditing(false);
              }
            }
            setSubmitting(false);
          });
        }}
      />
    );
  }

  return (
    <div className="gap-x-1 flex items-center p-1 mb-1 border rounded-lg">
      <p className="flex-grow text-center">{modality.name}</p>
      <Button type="button" size="sm" onClick={() => setEditing(true)}>
        <FAIcon icon="pencil-alt" />
      </Button>
      <Button
        type="button"
        className="border"
        kind="tertiary"
        size="sm"
        color="red"
        disabled={loading}
        isLoading={loading}
        onClick={() => {
          deleteModality({
            variables: { id: modality.id },
            refetchQueries: [
              {
                query: FETCH_CASE_PROGRAM,
                variables: { id: caseProgram.id },
              },
            ],
          }).then((resp) => {
            if (resp?.data?.deleteModality.errors) {
              toast.warning("Couldn't delete Modality");
            } else {
              toast.success(
                `Modality "${modality.name}" successfully deleted!`
              );
            }
          });
        }}
      >
        <FAIcon icon="trash-alt" />
      </Button>
    </div>
  );
};

/**
 * EditModalitiesForm.
 */

interface EditModalitiesFormProps {
  caseProgram: CaseProgram;
}

export const EditModalitiesForm: FC<EditModalitiesFormProps> = (props) => {
  const { caseProgram } = props;

  return (
    <div className="EditModalitiesForm">
      {caseProgram.modalities.length > 0 ? (
        caseProgram.modalities.map((modality) => (
          <ModalityRow
            key={modality.id}
            caseProgram={caseProgram}
            modality={modality}
          />
        ))
      ) : (
        <p className="p-2 py-4 mb-2 text-sm font-medium text-center text-gray-700 bg-gray-100 rounded-lg">
          No Modalities
        </p>
      )}
      <CreateModalityForm caseProgram={caseProgram} />
    </div>
  );
};
