import { FC, useCallback } from "react";
import { Spinner } from "components/Spinner";
import { useToast } from "layouts/PortalLayout/Toast";
import {
  DOWNLOAD_EXPORT,
  MutationData,
  MutationVariables,
} from "pages/AnalyticsPage/ExportsWidget/ExportItem";
import { useMutation, useQuery } from "react-apollo";
import { useRouteMatch } from "react-router-dom";
import gql from "graphql-tag";
import { DetailItem, DetailList } from "components/DetailList";
import { CircleIcon } from "components/CircleIcon";
import { Button } from "components/Button";
import { FAIcon } from "components/FAIcon";

const DATA_EXPORT = gql`
  query DataExport($id: UUID4!) {
    dataExport(id: $id) {
      id
      exports
      encryptionType
      status
      filename
      windowStart
      windowFinish
      completedAt
      insertedAt
    }
  }
`;

interface Data {
  dataExport: DataExportModel;
}

interface DataExportModel {
  id: string;
  exports: string[];
  encryptionType: string;
  status: string;
  filename: string;
  windowStart: string;
  windowFinish: string;
  completedAt: string;
  insertedAt: string;
}

interface RouteParams {
  id: string;
}

interface DataExportDownloadPageProps {}

export const DataExportDownloadPage: FC<DataExportDownloadPageProps> = () => {
  const match = useRouteMatch<RouteParams>();
  const { id } = match.params;

  const { data, loading, error } = useQuery<Data>(DATA_EXPORT, {
    variables: { id },
  });

  const toast = useToast();

  const [downloadDataExportMut, { loading: mutLoading }] = useMutation<
    MutationData,
    MutationVariables
  >(DOWNLOAD_EXPORT);

  const downloadDataExport = useCallback(async () => {
    const variables = { id };

    try {
      const { data } = await downloadDataExportMut({ variables });

      if (data?.downloadDataExport.url) {
        // it worked...
        window.open(data.downloadDataExport.url);
        toast.success("Download started.");
      } else if (data?.downloadDataExport.error) {
        toast.danger(data.downloadDataExport.error);
      } else {
        toast.danger("Something went wrong.");
      }
    } catch (e) {
      console.error(e);
      toast.danger("Something went wrong.");
    }
  }, [id, downloadDataExportMut, toast]);

  return (
    <div className="_DataExportDownloadPage flex justify-center px-6 py-12 text-left">
      {loading ? (
        <div className="p-8 text-center">
          <Spinner />
        </div>
      ) : error || !data?.dataExport ? (
        <p>Failed to load.</p>
      ) : (
        <div className="max-w-lg p-4 bg-white border rounded-lg shadow-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <CircleIcon icon="file-download" />
              <h4 className="ml-3 text-xl font-semibold text-gray-800">
                Data Export
              </h4>
            </div>
          </div>

          <DetailList className="pt-8">
            <DetailItem label="Date Range">
              {data.dataExport.windowStart} to {data.dataExport.windowFinish}
            </DetailItem>
            <DetailItem label="Status">{data.dataExport.status}</DetailItem>
            <DetailItem label="Encryption">
              {data.dataExport.encryptionType}
            </DetailItem>
            <DetailItem label="Exports">
              <ul className="pl-4 list-disc">
                {data.dataExport.exports.map((e) => (
                  <li key={e}>{e}</li>
                ))}
              </ul>
            </DetailItem>
          </DetailList>
          <div className="py-8 text-center">
            <Button
              type="button"
              kind="primary"
              size="lg"
              color="teal"
              onClick={downloadDataExport}
              isLoading={mutLoading}
              disabled={mutLoading}
            >
              Begin Download{" "}
              <span className="ml-2">
                <FAIcon icon="file-download" />
              </span>
            </Button>
          </div>

          <p className="text-xs italic text-gray-300">
            ID: {data.dataExport.id}
          </p>
        </div>
      )}
    </div>
  );
};
