import { FC } from "react";
import cx from "classnames";
import { addDays, startOfDay, lightFormat } from "date-fns";
import { FAIcon } from "components/FAIcon";
import "./WeekNav.css";

interface WeekNavProps {
  value: number;
  onChange(weekOffset: number): void;
}

const today = new Date();

export const WeekNav: FC<WeekNavProps> = (props) => {
  const { value, onChange } = props;
  const weekStart = startOfDay(addDays(today, value * 7));
  const weekEnd = addDays(weekStart, 6);

  return (
    <div className="_WeekNav gap-x-2 border-accent-green-500 flex items-center w-full max-w-4xl p-2 mx-auto my-4 border-b-2">
      <div>
        <button
          type="button"
          className={cx("a", { "WeekNav__nav-link--disabled": value === 0 })}
          onClick={() => onChange(value - 1)}
          disabled={value === 0}
        >
          <span style={{ margin: "0 0.5em" }}>
            <FAIcon icon="arrow-left" />
          </span>{" "}
          Prev Week
        </button>
      </div>

      <div className="flex-grow">
        <p className="WeekNav__date-range-label">
          {lightFormat(weekStart, "M/d/yyyy")} -{" "}
          {lightFormat(weekEnd, "M/d/yyyy")}
          {value === 0 ? (
            <span>(Upcoming week)</span>
          ) : value === -1 ? (
            <span>(Preceding week)</span>
          ) : value < -1 ? (
            <span>({value * -1} weeks ago)</span>
          ) : value === 1 ? (
            <span>(1 week out)</span>
          ) : value > 1 ? (
            <span>({value} weeks out)</span>
          ) : null}
        </p>
      </div>
      <div>
        <button type="button" className="a" onClick={() => onChange(value + 1)}>
          Next Week{" "}
          <span style={{ margin: "0 0.5em" }}>
            <FAIcon icon="arrow-right" />
          </span>
        </button>
      </div>
    </div>
  );
};
