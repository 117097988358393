import { FC, useCallback } from "react";
import { useMutation } from "react-apollo";
import { Formik, FormikHelpers } from "formik";
import gql from "graphql-tag";
import * as Yup from "yup";
import { HorizontalTextField } from "components/formik/TextField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { useToast } from "layouts/PortalLayout/Toast";
import { validPassword } from "lib/validPassword";
import { HorizontalField } from "components/formik/FieldStructure";
import { Button } from "components/Button";

const UPDATE_CURRENT_USER_PASSWORD = gql`
  mutation UpdateCurrentUserPassword($input: UpdatePasswordInput!) {
    updateCurrentUserPassword(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface UpdatePasswordData {
  updateCurrentUserPassword: {
    errors?: InputError[];
  };
}

interface FormValues {
  password: string;
  passwordConfirmation: string;
  currentPassword: string;
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object().shape({
  password: validPassword,
  passwordConfirmation: validPassword,
  currentPassword: Yup.string().required(
    "Your current password is required to update your password."
  ),
});

const initialValues: FormValues = {
  password: "",
  passwordConfirmation: "",
  currentPassword: "",
};

interface UpdatePasswordFormProps {
  onSuccess(): void;
}

export const UpdatePasswordForm: FC<UpdatePasswordFormProps> = (props) => {
  const { onSuccess } = props;

  const toast = useToast();
  const [updatePassword] = useMutation<UpdatePasswordData>(
    UPDATE_CURRENT_USER_PASSWORD
  );

  const onSubmit = useCallback(
    async (values: FormValues, formikActions: FormikHelpers<FormValues>) => {
      const { setStatus, setSubmitting } = formikActions;
      setStatus({ errors: null });
      if (values.password !== values.passwordConfirmation) {
        setStatus({
          errors: [
            {
              message: "New Password and New Password Confirmation must match",
            },
          ],
        });
        setSubmitting(false);
        return;
      }

      try {
        const { data } = await updatePassword({ variables: { input: values } });

        if (data?.updateCurrentUserPassword.errors) {
          setStatus({ errors: data.updateCurrentUserPassword.errors });
        } else {
          // Success
          toast.success("Password updated!");
          onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
      }
      setSubmitting(false);
    },
    [toast, updatePassword, onSuccess]
  );

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ status, isSubmitting, handleSubmit }) => (
        <div className="p-6">
          <h3 className="py-2 text-xl font-semibold">Update Password</h3>
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />

            <div className="mt-3">
              <HorizontalTextField
                type="password"
                id="updatePassword-password"
                name="password"
                label="New Password"
              />
            </div>

            <div className="mt-3">
              <HorizontalTextField
                type="password"
                id="updatePassword-passwordConfirmation"
                name="passwordConfirmation"
                label="Confirm New Password"
              />
            </div>

            <div className="mt-3">
              <HorizontalTextField
                type="password"
                id="updatePassword-currentPassword"
                name="currentPassword"
                label="Current Password"
              />
            </div>

            <div className="mt-3">
              <HorizontalField label="">
                <Button
                  type="submit"
                  kind="primary"
                  color="teal"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Update Password
                </Button>
              </HorizontalField>
            </div>
          </form>
        </div>
      )}
    </Formik>
  );
};
