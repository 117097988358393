import { FC } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { UpdateEmailForm } from "./UpdateEmailForm";
import { UpdatePasswordForm } from "./UpdatePasswordForm";

const CURRENT_USER_ACCOUNT_DATA_QUERY = gql`
  query MyUserEmailQuery {
    me {
      id
      email
      isSsoUser
      confirmedAt
    }
  }
`;

interface CurrentUserData {
  me: {
    id: string;
    email: string;
    isSsoUser: boolean;
    confirmedAt?: string;
  };
}

/**
 * InfoForSSOUser.
 */

interface InfoForSSOUserProps {
  email: string;
}

const InfoForSSOUser: FC<InfoForSSOUserProps> = (props) => {
  const { email } = props;
  return (
    <div className="p-6">
      <h5 className="py-2 text-lg font-semibold">
        Your account information is managed by a SSO (Single Sign-On) Identity
        Provider.
      </h5>
      <div className="AppointmentRequestShowPage__section__content">
        <label className="de-emph-label">Account Email</label>
        <p className="emph" style={{ whiteSpace: "nowrap" }}>
          {email}
        </p>
      </div>
    </div>
  );
};

/**
 * AccountForm.
 */

interface AccountFormProps {}

export const AccountForm: FC<AccountFormProps> = () => {
  const { data, loading, error, refetch } = useQuery<CurrentUserData>(
    CURRENT_USER_ACCOUNT_DATA_QUERY
  );

  return (
    <div className="bg-white rounded-lg shadow-lg">
      <NavTitle title="Settings » Account" />
      <GradientHeader
        title="Account Information"
        subtitle="Update your account information."
      />
      <div className="AccountForm">
        {loading ? (
          <h1>Loading...</h1>
        ) : error || !data ? (
          <div style={{ padding: "1rem" }}>
            <h1>Error Loading</h1>
          </div>
        ) : data.me.isSsoUser ? (
          <InfoForSSOUser email={data.me.email} />
        ) : (
          <>
            <UpdateEmailForm
              initialValues={{ email: data.me.email, password: "" }}
              onSuccess={refetch}
            />
            <UpdatePasswordForm onSuccess={refetch} />
          </>
        )}
      </div>
    </div>
  );
};
