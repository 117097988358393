import { FC, useState, useCallback } from "react";
import { useQuery } from "react-apollo";
import gql from "graphql-tag";
import { parseISO, lightFormat } from "date-fns";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { NewHealthPlanModal } from "./NewHealthPlanModal";
import { EditHealthPlanModal } from "./EditHealthPlanModal";
import { FAIcon } from "components/FAIcon";
import { Pill } from "components/Pill";
import { Table, TH, TD, TableContainer } from "components/Table";
import { Button } from "components/Button";
import { Spinner } from "components/Spinner";
import { Tooltip } from "components/Tooltip";

export const HEALTH_PLANS = gql`
  query ListHealthPlans {
    healthPlans {
      id
      name
      evicoreId
      externalSystemName
      active
      appealP2pCompletionEmailEnabled
      insertedAt
    }
  }
`;

export type HealthPlan = {
  id: string;
  name: string;
  evicoreId: string;
  externalSystemName: string;
  active: boolean;
  appealP2pCompletionEmailEnabled: boolean;
  insertedAt: string;
};

interface Data {
  healthPlans: HealthPlan[];
}

type ActiveModal = { type: "CREATE" } | { type: "EDIT"; healthPlanId: string };

interface HealthPlansProps {}

export const HealthPlans: FC<HealthPlansProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);

  const closeModal = useCallback(() => setActiveModal(null), []);

  const editHealthPlan = useCallback(
    (healthPlanId: string) => setActiveModal({ type: "EDIT", healthPlanId }),
    []
  );

  const { loading, error, data } = useQuery<Data>(HEALTH_PLANS);

  return (
    <>
      <NavTitle title="Settings » Health Plans" />

      <NewHealthPlanModal
        isOpen={activeModal?.type === "CREATE"}
        onClose={closeModal}
      />

      {activeModal?.type === "EDIT" ? (
        <EditHealthPlanModal
          isOpen
          healthPlanId={activeModal.healthPlanId}
          onClose={closeModal}
        />
      ) : null}

      <div className="bg-white rounded-lg shadow-lg">
        <GradientHeader
          title="Health Plans"
          subtitle="Manage your health plan options"
        />

        <div className="p-4 text-right">
          <Button
            type="button"
            kind="primary"
            color="teal"
            onClick={() => setActiveModal({ type: "CREATE" })}
          >
            <span className="mr-2">
              <FAIcon icon="user-plus" />
            </span>
            Add Health Plan
          </Button>
        </div>
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !(data && data.healthPlans) ? (
          <p>Failed to load</p>
        ) : (
          <div className="p-2">
            <TableContainer>
              <Table
                className="is-fullwidth is-hoverable table"
                style={{ marginBottom: 0 }}
              >
                <thead>
                  <tr>
                    <TH>Name</TH>
                    <TH>Active?</TH>
                    <TH>System</TH>
                    <TH>Created At</TH>
                    <TH>Edit</TH>
                  </tr>
                </thead>
                <tbody>
                  {data.healthPlans.map((healthPlan) => (
                    <tr key={healthPlan.id}>
                      <TD>
                        <p className="font-semibold text-gray-900">
                          {healthPlan.name}
                        </p>
                        <p className="text-xs text-gray-500">
                          ID:{" "}
                          <span className="text-gray-700">
                            {healthPlan.evicoreId}
                          </span>
                        </p>
                      </TD>
                      <TD>
                        <div className="flex items-center">
                          <Pill
                            color={healthPlan.active ? "green" : "gray"}
                            label={healthPlan.active ? "Active" : "Inactive"}
                          />
                          {healthPlan.appealP2pCompletionEmailEnabled ? (
                            <Tooltip tip="Email upon Appeal P2P Completion Enabled">
                              <FAIcon
                                className="ml-2 text-green-400"
                                icon="envelope"
                              />
                            </Tooltip>
                          ) : null}
                        </div>
                      </TD>
                      <TD>{healthPlan.externalSystemName}</TD>
                      <TD>
                        {lightFormat(parseISO(healthPlan.insertedAt), "M/d/yy")}
                      </TD>
                      <TD>
                        <Button
                          type="button"
                          size="xs"
                          onClick={() => editHealthPlan(healthPlan.id)}
                        >
                          <span className="mr-2">
                            <FAIcon icon="pencil-alt" />
                          </span>
                          Edit
                        </Button>
                      </TD>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <div className="text-center" style={{ padding: "1rem" }}>
                <p className="text-gray-500"> End of List</p>
              </div>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};
