import { FC } from "react";
import { useMutation, useQuery } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { Formik } from "formik";
import { useToast } from "layouts/PortalLayout/Toast";
import { HorizontalSelectField } from "components/formik/SelectField";
import { HorizontalPreferredDaysField } from "components/formik/PreferredDaysField";
import { HorizontalPreferredTimesField } from "components/formik/PreferredTimesField";
import { defaultDays } from "components/PreferredDays";
import { flatDefaultTimes } from "components/PreferredTimes";
import { Modal, ModalHeader } from "components/Modal";
import { Day } from "components/PreferredDays/PreferredDays";
import { Button } from "components/Button";

const days = defaultDays;

const allTimes = flatDefaultTimes;

const TIME_PREFERENCE_FORM_QUERY = gql`
  query TimePreferenceFormQuery($appointmentRequestId: UUID4!) {
    timeZoneNames
    appointmentRequest(id: $appointmentRequestId) {
      id
      timePreference {
        id
        preferredDays
        preferredTimes
        timeZoneName
      }
    }
  }
`;

interface Data {
  timeZoneNames: string[];
  appointmentRequest: {
    id: string;
    timePreference: {
      id: string;
      preferredDays: Day[];
      preferredTimes: string[];
      timeZoneName: string;
    };
  };
}

const UPDATE_TIME_PREFERENCE = gql`
  mutation UpdateTimePreference(
    $appointmentRequestId: UUID4!
    $timePreference: TimePreferenceInput!
  ) {
    updateAppointmentRequestTimePreference(
      id: $appointmentRequestId
      timePreference: $timePreference
    ) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        timePreference {
          id
          preferredDays
          preferredTimes
          timeZoneName
        }
      }
    }
  }
`;

interface MutationData {
  updateAppointmentRequestTimePreference: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      timePreference: {
        id: string;
        preferredDays: Day[];
        preferredTimes: string[];
        timeZoneName: string;
      };
    };
  };
}

interface UpdateTimePreferenceModalProps {
  appointmentRequestId: string;
  isOpen: boolean;
  onClose(): void;
}

export const UpdateTimePreferenceModal: FC<UpdateTimePreferenceModalProps> = (
  props
) => {
  const { appointmentRequestId, isOpen, onClose } = props;
  const toast = useToast();
  const { data, loading, error } = useQuery<Data>(TIME_PREFERENCE_FORM_QUERY, {
    variables: { appointmentRequestId },
  });
  const [updateTimePreference] = useMutation<MutationData>(
    UPDATE_TIME_PREFERENCE
  );

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="_UpdateTimePreferenceModal w-full max-w-3xl"
    >
      <ModalHeader
        icon="calendar-day"
        title="Update Preferred Times"
        onClose={onClose}
      />
      <div className="px-4 pt-0 pb-4 bg-white rounded-lg shadow-lg">
        {loading ? (
          <p>Loading...</p>
        ) : error || !(data && data.appointmentRequest) ? (
          <p>Failed to load.</p>
        ) : (
          <Formik
            initialValues={{
              preferredDays:
                data.appointmentRequest.timePreference.preferredDays,
              preferredTimes:
                data.appointmentRequest.timePreference.preferredTimes,
              timeZoneName: data.appointmentRequest.timePreference.timeZoneName,
            }}
            validationSchema={Yup.object().shape({
              preferredDays: Yup.array()
                .required("Required")
                .min(1, "You must select at least 1 day")
                .ensure()
                .of(Yup.mixed().oneOf(days)),
              preferredTimes: Yup.array()
                .required("Required")
                .min(1, "You must select at least 1 time range")
                .ensure()
                .of(Yup.mixed().oneOf(allTimes)),
              timeZoneName: Yup.string().required("Required"),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              const variables = {
                appointmentRequestId,
                timePreference: values,
              };
              updateTimePreference({
                variables,
              }).then((resp) => {
                if (resp?.data?.updateAppointmentRequestTimePreference.errors) {
                  setStatus({
                    errors:
                      resp.data.updateAppointmentRequestTimePreference.errors,
                  });
                } else if (
                  resp?.data?.updateAppointmentRequestTimePreference
                    .appointmentRequest
                ) {
                  // it worked...
                  toast.success("Updated preferred times!");
                  onClose();
                }
                setSubmitting(false);
              });
            }}
          >
            {({ handleSubmit, isSubmitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="mt-5">
                  <HorizontalPreferredDaysField
                    name="preferredDays"
                    label="Preferred Days"
                    showInstructions
                  />
                </div>

                <div className="mt-5">
                  <HorizontalPreferredTimesField
                    name="preferredTimes"
                    label="Preferred Times"
                    showInstructions
                  />
                </div>

                <div className="mt-5">
                  <HorizontalSelectField
                    name="timeZoneName"
                    label="Time Zone"
                    options={data.timeZoneNames.map((tz: string) => ({
                      value: tz,
                      label: tz,
                    }))}
                  />
                </div>

                <div className="flex justify-end mt-6">
                  <Button
                    type="submit"
                    kind="primary"
                    color="teal"
                    size="lg"
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                  >
                    Submit
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
