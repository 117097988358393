import { FC, useState } from "react";
import { useQuery } from "react-apollo";
import {
  FormCaseProfileFields,
  CaseInfo,
} from "../CaseLookupModal/CaseLookupModal";
import { HorizontalTextField } from "components/formik/TextField";
import {
  NEW_CASE_PROFILE_FORM_DATA_QUERY,
  Data,
  HealthPlanOption,
  StateOption,
  ModalityOption,
} from "../CaseProfileForm/CaseProfileForm";
import { HorizontalSelectField } from "components/formik/SelectField";
import { CircleIcon } from "components/CircleIcon";
import { DetailList, DetailItem } from "components/DetailList";
import { FAIcon } from "components/FAIcon";
import { CaseLookupParams } from "../NewAppointmentRequestPage";
import { SingleCheckboxInput } from "components/formik/SingleCheckboxField";
import { translateLOR } from "lib/kentuckyWording";
import { hMmA, mmDdYyyy } from "lib/dateFormatters";
import { icdRequired } from "lib/stateRegulations";

const APIResponseDebugger: FC<{ json: JSONObject }> = ({ json }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <button
          type="button"
          className="text-xs text-blue-400"
          onClick={() => setIsOpen(!isOpen)}
          style={{
            cursor: "pointer",
            border: "none",
            background: "transparent",
            padding: 0,
            margin: 0,
          }}
        >
          {isOpen ? "Hide API Response JSON" : "Show API Response JSON"}
        </button>
      </div>

      {isOpen ? (
        <pre className="mono max-w-full overflow-scroll text-xs bg-gray-100">
          {JSON.stringify(JSON.parse(json as any), null, 2)}
        </pre>
      ) : null}
    </div>
  );
};

const Check = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#96EBB5"
      />
      <path
        d="M9.99999 14.59L16.3 8.29003C16.4924 8.12746 16.7392 8.04353 16.9908 8.05499C17.2425 8.06646 17.4805 8.17249 17.6574 8.35188C17.8343 8.53127 17.9369 8.77081 17.9448 9.02261C17.9527 9.27441 17.8653 9.5199 17.7 9.71003L10.7 16.71C10.5131 16.8933 10.2617 16.9959 9.99999 16.9959C9.73824 16.9959 9.48692 16.8933 9.29999 16.71L6.29999 13.71C6.13471 13.5199 6.04728 13.2744 6.05518 13.0226C6.06308 12.7708 6.16572 12.5313 6.34258 12.3519C6.51945 12.1725 6.75751 12.0665 7.00917 12.055C7.26083 12.0435 7.50754 12.1275 7.69999 12.29L9.99999 14.59Z"
        fill="#3BB06B"
      />
    </svg>
  );
};

const X = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
        fill="#F09C9E"
      />
      <path
        d="M13.4099 12L16.2399 14.83C16.4038 15.0213 16.4894 15.2674 16.4797 15.519C16.4699 15.7707 16.3656 16.0095 16.1875 16.1876C16.0094 16.3656 15.7707 16.47 15.519 16.4797C15.2673 16.4894 15.0212 16.4038 14.8299 16.24L11.9999 13.41L9.16994 16.24C9.0803 16.3447 8.96998 16.4297 8.84591 16.4897C8.72185 16.5497 8.58672 16.5835 8.449 16.5888C8.31128 16.5941 8.17395 16.5709 8.04562 16.5206C7.9173 16.4704 7.80075 16.3941 7.70329 16.2966C7.60584 16.1992 7.52958 16.0826 7.47931 15.9543C7.42903 15.826 7.40583 15.6887 7.41115 15.5509C7.41647 15.4132 7.4502 15.2781 7.51022 15.154C7.57024 15.03 7.65526 14.9196 7.75994 14.83L10.5899 12L7.75994 9.16999C7.59612 8.97869 7.51051 8.73261 7.52023 8.48094C7.52995 8.22926 7.63429 7.99052 7.81238 7.81243C7.99048 7.63433 8.22921 7.53 8.48089 7.52028C8.73257 7.51056 8.97864 7.59616 9.16994 7.75999L11.9999 10.59L14.8299 7.75999C15.0212 7.59616 15.2673 7.51056 15.519 7.52028C15.7707 7.53 16.0094 7.63433 16.1875 7.81243C16.3656 7.99052 16.4699 8.22926 16.4797 8.48094C16.4894 8.73261 16.4038 8.97869 16.2399 9.16999L13.4099 12Z"
        fill="#B03336"
      />
    </svg>
  );
};

const EligibleIndicator: FC<{ eligible: boolean }> = ({ eligible }) => {
  return (
    <div>
      {eligible ? (
        <div className="flex items-center">
          <Check />
          <p className="ml-2 font-bold text-green-800">P2P Eligible</p>
        </div>
      ) : (
        <div className="flex items-center">
          <X />
          <p className="ml-2 font-bold text-red-800">P2P Ineligible</p>
        </div>
      )}
    </div>
  );
};

const WarnEditButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        cursor: "pointer",
        border: "none",
        lineHeight: 1,
        margin: 0,
        padding: 0,
      }}
      className="text-lg font-semibold text-yellow-700 bg-transparent"
    >
      <span className="mr-2 text-base">
        <FAIcon icon="exclamation-triangle" />
      </span>
      Click to Set Value
    </button>
  );
};

type CaseInfoDisplayProps = {
  caseProfileValues: FormCaseProfileFields;
  caseInfo?: CaseInfo;
  toggleEditing: () => void;
  states: StateOption[];
  modalities: ModalityOption[];
  healthPlans: HealthPlanOption[];
  reviewLevels: Option[];
  systemNames: string[];
};

const CaseInfoDisplay: FC<CaseInfoDisplayProps> = ({
  caseProfileValues,
  caseInfo,
  toggleEditing,
  states,
  modalities,
  healthPlans,
  reviewLevels,
  systemNames,
}) => {
  const stateLabel = (
    states.find((s) => s.id === caseProfileValues.memberStateId) || {
      name: undefined,
    }
  ).name;

  const modalityLabel = (
    modalities.find((m) => m.id === caseProfileValues.modalityId) || {
      name: undefined,
    }
  ).name;

  const healthPlanLabel = (
    healthPlans.find((hp) => hp.id === caseProfileValues.healthPlanId) || {
      name: undefined,
    }
  ).name;

  const reviewLevel = reviewLevels.find(
    (rl) => rl.value === caseProfileValues.levelOfReview
  ) || {
    label: undefined,
  };

  const reviewLevelLabel = translateLOR(
    reviewLevel.label,
    caseProfileValues.insurancePlanCode,
    stateLabel
  );

  const specialtyLabel =
    (caseInfo &&
    caseInfo.systemName === "Isaac" &&
    caseInfo.caseSkills &&
    caseInfo.caseSkills.length > 0
      ? caseInfo.caseSkills[0].name
      : caseInfo?.orderingPhysicianSpecialty?.name) || "";

  const showSpecialtyLabel = Boolean(
    specialtyLabel &&
      (caseInfo?.systemName === "Isaac" || caseInfo?.sameSpecialtyMatchRequired)
  );

  return (
    <div>
      <div className="flex p-2">
        <div className="flex-1">
          <h5 className="text-sm font-semibold text-gray-800">
            Member Information
          </h5>
          <div className="p-4 mx-2 my-3 border border-gray-100 rounded shadow">
            <DetailList>
              <DetailItem label="Name">
                {caseProfileValues.memberFirstName}&nbsp;
                {caseProfileValues.memberLastName}
              </DetailItem>
              <DetailItem label="DOB">{caseProfileValues.memberDob}</DetailItem>
              <DetailItem label="State">{stateLabel}</DetailItem>
              <DetailItem label="Health Plan">{healthPlanLabel}</DetailItem>
              <DetailItem label="Plan Type">
                {caseProfileValues.healthPlanType}
              </DetailItem>
              <DetailItem label="Member ID">
                {caseProfileValues.memberMembershipNumber}
              </DetailItem>
            </DetailList>
          </div>
        </div>
        <div className="flex-1">
          <h5 className="text-sm font-semibold text-gray-800">
            Case P2P Information
          </h5>
          <div className="p-4 mx-2 my-3 border border-gray-100 rounded shadow">
            <DetailList>
              <DetailItem label="Episode ID">
                {caseProfileValues.episodeId || "-"}
              </DetailItem>
              <DetailItem label="P2P Valid Until">
                {caseProfileValues.p2pValidUntilDatetime
                  ? `${mmDdYyyy(
                      caseProfileValues.p2pValidUntilDatetime
                    )} ${hMmA(caseProfileValues.p2pValidUntilDatetime)}`
                  : caseProfileValues.p2pValidUntilDate || "-"}
              </DetailItem>
              <DetailItem label="Modality">
                {modalityLabel ? (
                  modalityLabel
                ) : caseProfileValues.isP2pEligible ? (
                  <WarnEditButton onClick={toggleEditing} />
                ) : (
                  "-"
                )}
              </DetailItem>
              <DetailItem label="Level of Review">
                {reviewLevelLabel ? (
                  reviewLevelLabel
                ) : caseProfileValues.isP2pEligible ? (
                  <WarnEditButton onClick={toggleEditing} />
                ) : (
                  "-"
                )}
              </DetailItem>
              <DetailItem label="System Name">
                {caseProfileValues.externalSystemName}
              </DetailItem>
              {showSpecialtyLabel ? (
                <DetailItem label="Specialty">{specialtyLabel}</DetailItem>
              ) : null}
              {caseProfileValues.sameStateLicensureRequired &&
              caseInfo &&
              caseInfo.sameStateLicensureState?.abbreviation ? (
                <DetailItem label="SSL State">
                  {caseInfo.sameStateLicensureState.abbreviation}
                </DetailItem>
              ) : null}
              {icdRequired(
                stateLabel || "",
                caseProfileValues.healthPlanType
              ) ? (
                <DetailItem label="ICD10 Code">
                  {caseProfileValues.icd10Code || (
                    <WarnEditButton onClick={toggleEditing} />
                  )}
                </DetailItem>
              ) : null}
              <DetailItem label="CPT Codes">
                {caseProfileValues.cptCodes?.join(", ") || "-"}
              </DetailItem>
            </DetailList>
          </div>
        </div>
      </div>
    </div>
  );
};

interface CaseLookupCardProps {
  index: number;
  isEditing: boolean;
  isEditable: boolean;
  caseLookupParams: CaseLookupParams;
  toggleEditing(): void;
  onRemove(): void;
  caseProfileValues: FormCaseProfileFields;
  caseInfo?: CaseInfo;
}

export const CaseLookupCard: FC<CaseLookupCardProps> = (props) => {
  const {
    index,
    isEditing,
    isEditable,
    toggleEditing,
    onRemove,
    caseLookupParams,
    caseProfileValues,
    caseInfo,
  } = props;

  // NB: this will eventually cause purgecss bugs
  const noticeColor = caseProfileValues.isP2pEligible ? "accent-green" : "red";
  const noticeClassName = caseProfileValues.isP2pEligible
    ? "bg-green-50 border-green-200 text-green-800"
    : "bg-red-50 border-red-200 text-red-800";

  const { data, loading, error } = useQuery<Data>(
    NEW_CASE_PROFILE_FORM_DATA_QUERY
  );

  const stateLabel = (
    data?.states.find((s) => s.id === caseProfileValues.memberStateId) || {
      name: undefined,
    }
  ).name;

  const lookupMessage = translateLOR(
    caseProfileValues.lookupMessage,
    caseProfileValues.insurancePlanCode,
    stateLabel
  );

  return loading ? (
    <h1>Loading</h1>
  ) : error || !data ? (
    <div style={{ padding: "1.5rem" }}>
      <h1>Failed to Load</h1>
    </div>
  ) : (
    <div className="CaseLookupCard px-4 py-3 mb-4 bg-white border border-gray-200 rounded-lg shadow-lg">
      <div>
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <h4 className="text-lg text-gray-700">
              Case Ref #:{" "}
              <span className="font-semibold text-gray-900">
                {caseProfileValues.caseReferenceNumber}
              </span>
            </h4>
            {isEditable || true ? (
              <button
                type="button"
                className="ml-2 text-lg font-semibold text-blue-500 bg-transparent"
                onClick={toggleEditing}
                style={{
                  border: "none",
                  padding: 0,
                  display: "inline",
                  cursor: "pointer",
                }}
              >
                Edit Details
              </button>
            ) : null}
          </div>
          <div className="flex">
            <button
              type="button"
              onClick={onRemove}
              className="mr-2 text-lg font-semibold text-red-500 bg-transparent"
              style={{
                border: "none",
                padding: 0,
                display: "inline",
                cursor: "pointer",
              }}
            >
              Remove
            </button>
            <EligibleIndicator eligible={caseProfileValues.isP2pEligible} />
          </div>
        </div>
        {lookupMessage ? (
          <div id="message-container" className="flex justify-around px-4 py-3">
            <div
              id="message-box"
              className={`${noticeClassName} border flex font-medium items-center p-2 rounded`}
            >
              <CircleIcon
                className="flex-shrink-0"
                icon="exclamation"
                size={32}
                color={noticeColor}
              />
              <p className="ml-4">{lookupMessage}</p>
            </div>
          </div>
        ) : null}

        {process.env.REACT_APP_CASE_LOOKUP_DEBUG === "true" ? (
          <APIResponseDebugger json={caseProfileValues.evicoreApiResponse} />
        ) : null}

        {isEditing ? (
          <div className="CaseLookupForm">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="block pt-1 pb-3 text-base font-semibold">
                  Case Reference Number
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <p>{caseLookupParams.caseReferenceNumber}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="block pt-1 pb-3 text-base font-semibold">
                  Member Date of Birth
                </label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <p>{caseLookupParams.memberDob}</p>
                  </div>
                </div>
              </div>
            </div>

            <HorizontalTextField
              name={`caseProfiles[${index}].memberMembershipNumber`}
              label="Member ID"
            />
            <HorizontalTextField
              name={`caseProfiles[${index}].memberFirstName`}
              label="Member First Name"
            />
            <HorizontalTextField
              name={`caseProfiles[${index}].memberLastName`}
              label="Member Last Name"
            />

            <HorizontalSelectField
              name={`caseProfiles[${index}].memberStateId`}
              label="Member State"
              options={data.states.map((s) => ({
                value: s.id,
                label: s.name,
              }))}
            />
            <HorizontalSelectField
              name={`caseProfiles[${index}].healthPlanId`}
              label="Health Plan"
              options={data.healthPlans.map((hp) => ({
                value: hp.id,
                label: hp.name,
              }))}
            />
            <HorizontalSelectField
              name={`caseProfiles[${index}].modalityId`}
              label="Modality"
              options={data.modalities.map((m) => ({
                value: m.id,
                label: m.name,
              }))}
            />
            <HorizontalSelectField
              name={`caseProfiles[${index}].levelOfReview`}
              label="Level of Review"
              options={data.reviewLevels}
            />
            <HorizontalSelectField
              name={`caseProfiles[${index}].externalSystemName`}
              label="System Name"
              options={data.externalSystemNames.map((name: string) => ({
                value: name,
                label: name,
              }))}
            />
            {icdRequired(stateLabel || "", caseProfileValues.healthPlanType) ? (
              <HorizontalTextField
                name={`caseProfiles[${index}].icd10Code`}
                label="ICD10 Code"
              />
            ) : null}
          </div>
        ) : (
          <CaseInfoDisplay
            caseProfileValues={caseProfileValues}
            caseInfo={caseInfo}
            toggleEditing={toggleEditing}
            states={data.states}
            modalities={data.modalities}
            healthPlans={data.healthPlans}
            reviewLevels={data.reviewLevels}
            systemNames={data.externalSystemNames}
          />
        )}
      </div>
      {!caseProfileValues.isP2pEligible ? (
        <div className="flex flex-col items-center justify-center p-4">
          <SingleCheckboxInput
            name={`caseProfiles[${index}].submitThisCase`}
            checkboxLabel={
              caseProfileValues.levelOfReview === "p2p_consult_only"
                ? "Proceed with P2P Consult Only"
                : "Override and submit despite P2P eligibility"
            }
          />
        </div>
      ) : null}
    </div>
  );
};
