import React, { FC } from "react";
import qs from "query-string";
import { Link, useLocation } from "react-router-dom";
import { ResetPasswordForm } from "./ResetPasswordForm";
import { AuthConsumer } from "components/AuthContext";
import { coalesceString } from "lib/coalesce";
// import evicoreLogoSrc from "images/eviCore_big_logo.png";
import evicoreLogoSrc from "images/evicore_logo_full.png";

export const ResetPasswordPage: FC = () => {
  const location = useLocation();
  const { id, token } = qs.parse(location.search);
  return (
    <div className="border-teal-50 p-4 bg-white border-t-4 rounded shadow-lg">
      <img
        src={evicoreLogoSrc}
        alt="logo"
        width="200"
        className="object-contain mx-auto"
      />
      <p className="text-accent-green-500 py-2 text-lg font-bold">P2P Portal</p>
      <div>
        {id && token ? (
          <AuthConsumer>
            {({ signIn }) => (
              <ResetPasswordForm
                id={coalesceString(id)}
                token={coalesceString(token)}
                onSignIn={signIn}
              />
            )}
          </AuthConsumer>
        ) : (
          <div>
            <h1 className="is-size-2">Invalid Password Reset URL</h1>
            <p>
              <Link to="/auth/forgot_password">Click Here</Link> to request a
              new password reset link
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
