import { FC } from "react";

const colorClassNames: Record<AppColor, string> = {
  teal: "bg-teal-50 text-teal-600 border-teal-200",
  mint: "bg-mint-50 text-mint-600 border-mint-200",
  "accent-green":
    "bg-accent-green-50 text-accent-green-600 border-accent-green-200",
  "dark-teal": "bg-dark-teal-50 text-dark-teal-600 border-dark-teal-200",
  gray: "bg-gray-50 text-gray-600 border-gray-200",
  blue: "bg-blue-50 text-blue-600 border-blue-200",
  red: "bg-red-50 text-red-600 border-red-200",
  green: "bg-green-50 text-green-600 border-green-200",
  purple: "bg-purple-50 text-purple-600 border-purple-200",
  gold: "bg-gold-50 text-gold-600 border-gold-200",
  yellow: "bg-yellow-50 text-yellow-600 border-yellow-200",
};

interface PillProps {
  color: AppColor;
  label: string;
}

export const Pill: FC<PillProps> = (props) => {
  const { color, label } = props;

  return (
    <span
      className={`_Pill inline-block py-1 px-3 leading-5 text-xs uppercase rounded-full font-semibold border ${colorClassNames[color]}`}
    >
      {label}
    </span>
  );
};
