import { library } from "@fortawesome/fontawesome-svg-core";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faLock } from "@fortawesome/free-solid-svg-icons/faLock";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons/faArrowRight";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons/faArrowUp";
import { faBars } from "@fortawesome/free-solid-svg-icons/faBars";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faTags } from "@fortawesome/free-solid-svg-icons/faTags";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons/faCalendarAlt";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons/faQuestionCircle";
import { faInbox } from "@fortawesome/free-solid-svg-icons/faInbox";
import { faChartBar } from "@fortawesome/free-solid-svg-icons/faChartBar";
import { faCog } from "@fortawesome/free-solid-svg-icons/faCog";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons/faAngleDown";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons/faFileDownload";
import { faKey } from "@fortawesome/free-solid-svg-icons/faKey";
import { faTimes } from "@fortawesome/free-solid-svg-icons/faTimes";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faPhone } from "@fortawesome/free-solid-svg-icons/faPhone";
// import { faComments } from '@fortawesome/free-solid-svg-icons/faComments'
import { faCheck } from "@fortawesome/free-solid-svg-icons/faCheck";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons/faPlusCircle";
import { faExclamation } from "@fortawesome/free-solid-svg-icons/faExclamation";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons/faExclamationTriangle";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons/faUserEdit";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons/faUserPlus";
import { faBell } from "@fortawesome/free-solid-svg-icons/faBell";
import { faUsers } from "@fortawesome/free-solid-svg-icons/faUsers";
import { faUser } from "@fortawesome/free-solid-svg-icons/faUser";
import { faHospitalAlt } from "@fortawesome/free-solid-svg-icons/faHospitalAlt";
import { faListAlt } from "@fortawesome/free-solid-svg-icons/faListAlt";
import { faStethoscope } from "@fortawesome/free-solid-svg-icons/faStethoscope";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons/faPencilAlt";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons/faClipboardList";
import { faAddressCard } from "@fortawesome/free-regular-svg-icons/faAddressCard";
import { faCalendarAlt as farCalendarAlt } from "@fortawesome/free-regular-svg-icons/faCalendarAlt";
import { faCalendarCheck } from "@fortawesome/free-regular-svg-icons/faCalendarCheck";
import { faCalendarTimes } from "@fortawesome/free-regular-svg-icons/faCalendarTimes";
import { faClock } from "@fortawesome/free-regular-svg-icons/faClock";
// user-md
import { faUserMd } from "@fortawesome/free-solid-svg-icons/faUserMd";
import { faLevelUpAlt } from "@fortawesome/free-solid-svg-icons/faLevelUpAlt";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import { faFilter } from "@fortawesome/free-solid-svg-icons/faFilter";
import { faSearch } from "@fortawesome/free-solid-svg-icons/faSearch";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons/faChevronDown";
import { faChartLine } from "@fortawesome/free-solid-svg-icons/faChartLine";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons/faEnvelopeOpenText";
import { faArchive } from "@fortawesome/free-solid-svg-icons/faArchive";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";

/**
 * Warning: side-effects!
 * See: https://github.com/FortAwesome/react-fontawesome/tree/9ee4f58cfc07296f250b870249a66ed493f8589f#build-a-library-to-reference-icons-throughout-your-app-more-conveniently
 * Building this "library" allows us to only import the SVG icons we care to use,
 * imported individually above, and then use them by string reference in components.
 * This function needs to be run in an initializing module of the app/site.
 */
export default function createIconLibrary() {
  library.add(
    faEnvelope,
    faLock,
    faKey,
    faArrowRight,
    faArrowLeft,
    faArrowUp,
    faBars,
    faHome,
    faCalendarAlt,
    faCalendarDay,
    faQuestionCircle,
    faInbox,
    faChartBar,
    faCog,
    faTrashAlt,
    faAngleDown,
    faFileDownload,
    faCheck,
    faCheckCircle,
    faTimesCircle,
    faPlusCircle,
    faExclamation,
    faExclamationTriangle,
    faTimes,
    faPlus,
    faPencilAlt,
    faUserEdit,
    faUserPlus,
    faBell,
    faUsers,
    faUser,
    faHospitalAlt,
    faMapMarkerAlt,
    faListAlt,
    faUserMd,
    faStethoscope,
    faAddressCard,
    faClipboardList,
    farCalendarAlt,
    faCalendarCheck,
    faCalendarTimes,
    faClock,
    faInfoCircle,
    faLevelUpAlt,
    faFilter,
    faSearch,
    faChevronDown,
    faChartLine,
    faPhone,
    faEnvelopeOpenText,
    faArchive,
    faTags,
    faExternalLinkAlt
  );
}
