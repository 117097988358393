import { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { CircleIcon } from "components/CircleIcon";
import { FAIcon } from "components/FAIcon";
import "./ModalHeader.css";

interface ModalHeaderProps {
  icon: IconProp;
  title: string;
  onClose(): void;
  color?: AppColor;
}

export const ModalHeader: FC<ModalHeaderProps> = (props) => {
  const { icon, title, onClose, color = "accent-green" } = props;
  return (
    <div className="ModalHeader">
      <div className="flex items-center justify-between mb-2">
        <div className="flex items-center mx-6 my-4">
          <CircleIcon icon={icon} color={color} className="mr-4" />
          <h4 className="text-xl font-semibold text-gray-800">{title}</h4>
        </div>

        <div
          className="hover:text-gray-600 p-3 mr-3 text-xl text-gray-400 cursor-pointer"
          role="button"
          onClick={onClose}
        >
          <FAIcon icon="times" />
        </div>
      </div>
    </div>
  );
};
