import { FC } from "react";
import gql from "graphql-tag";
import { Formik } from "formik";
import * as Yup from "yup";
import { useToast } from "layouts/PortalLayout/Toast";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { DateMaskField } from "components/formik/DateMaskField";
import { useMutation } from "react-apollo";
import { Button } from "components/Button";
import { startOfDay } from "date-fns";
import { mmDdYyyy } from "lib/dateFormatters";
import { Filter, filterLabels } from "../FilterPanel/FilterPanel";
import { VerticalField } from "components/formik/FieldStructure";
import { DetailItem, DetailList } from "components/DetailList";

const CREATE_EXPORT = gql`
  mutation CreateDataExport($input: CreateDataExportInput!) {
    createDataExport(input: $input) {
      errors {
        key
        message
      }
      export {
        id
        filename
      }
    }
  }
`;

interface MutationData {
  createDataExport: {
    errors?: InputError[];
    export?: {
      id: string;
      filename: string;
    };
  };
}

interface WorklistExportFormProps {
  onClose(): void;
  filter: Partial<Filter>;
  initialValues: {
    windowStart?: string | null;
    windowFinish?: string | null;
  };
}

const today = new Date();
const dayStart = startOfDay(today);

export const WorklistExportForm: FC<WorklistExportFormProps> = (props) => {
  const { onClose, initialValues, filter } = props;

  delete filter.createdAfter;
  delete filter.createdBefore;
  if (filter.caseReferenceNumber === "") {
    delete filter.caseReferenceNumber;
  }
  console.log({ filter });

  const [createExport] = useMutation<MutationData>(CREATE_EXPORT);

  const toast = useToast();

  return (
    <div style={{ padding: "1rem" }}>
      <Formik
        initialValues={{
          windowStart: initialValues.windowStart || mmDdYyyy(dayStart),
          windowFinish: initialValues.windowFinish || mmDdYyyy(today),
          password: "",
          encryptionType: "AES",
          exports: ["appointment_requests"],
        }}
        validationSchema={Yup.object().shape({
          password: Yup.string()
            .matches(/^[A-Za-z\d@$!%*#?&]+$/, {
              message: "Can only contain letters, numbers or @$!%*#?&",
              excludeEmptyString: true,
            })
            .min(8)
            .required("Required"),
          encryptionType: Yup.string()
            .oneOf(["AES", "PGP"], "Invalid selection")
            .required("Required"),
          exports: Yup.array()
            .min(1, "Must select at least 1")
            .required("Required"),
        })}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setStatus({ errors: null });
          createExport({
            variables: { input: { filters: filter, ...values } },
          }).then((res) => {
            if (res.data?.createDataExport.errors) {
              setStatus({
                errors: res.data.createDataExport.errors,
              });
            } else if (res.data?.createDataExport.export) {
              // It worked...
              toast.success(
                "Started data export. A link to download the file will be emailed to you shortly."
              );
              onClose();
            }
            setSubmitting(false);
          });
        }}
      >
        {({ status, isSubmitting, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FormStatusErrors status={status} />
            <div className="gap-x-3 grid grid-cols-2">
              <DateMaskField
                name="windowStart"
                label="Window Start"
                minYear={2010}
                maxYear={2040}
              />

              <DateMaskField
                name="windowFinish"
                label="Window End"
                minYear={2010}
                maxYear={2040}
              />
            </div>

            <div className="mt-3">
              <TextField
                type="password"
                name="password"
                placeholder="••••••••••"
                label="Password for file encryption"
                icon="lock"
                autoFocus
              />
            </div>

            <div className="mt-3">
              <VerticalField label="Filters">
                <DetailList>
                  {Object.entries(filter).map(([key, value]) => (
                    <DetailItem key={key} label={filterLabels[key] || key}>
                      {Array.isArray(value) ? value.join(", ") : value}
                    </DetailItem>
                  ))}
                </DetailList>
              </VerticalField>
            </div>

            <div style={{ margin: "1.5rem auto 1rem", textAlign: "center" }}>
              <Button
                type="submit"
                kind="primary"
                color="teal"
                isLoading={isSubmitting}
                disabled={isSubmitting}
              >
                Export
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};
