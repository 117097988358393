import { FC } from "react";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { Link } from "react-router-dom";
import evicoreLogoSrc from "images/evicore_logo_full.png";

export const ForgotPasswordPage: FC = () => {
  return (
    <div className="_ForgotPasswordPage border-accent-green-400 p-4 bg-white border-t-4 rounded-lg shadow-lg">
      <img
        className="mx-auto mt-6"
        src={evicoreLogoSrc}
        alt="logo"
        width="200"
      />
      <p className="text-accent-green-500 py-2 text-lg font-bold">P2P Portal</p>

      <div className="md:px-4 w-full max-w-sm mx-auto mt-10 text-left">
        <ForgotPasswordForm />

        <div className="my-4 text-sm text-center">
          <Link className="link ml-2 leading-loose" to="/auth/old_sign_in">
            Sign in with your email and password
          </Link>
          <p className="mb-2">
            Using SSO?
            <Link className="link ml-2" to="/auth/sign_in">
              Sign in with SSO
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
