import { FC, useState, useCallback } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import { parseISO, lightFormat } from "date-fns";
import ReactModal from "react-modal";
import { FAIcon } from "components/FAIcon";
import { GradientHeader } from "../../GradientHeader";
import { NavTitle } from "layouts/PortalLayout/NavTitle";
import { useToast } from "layouts/PortalLayout/Toast";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { TextField } from "components/formik/TextField";
import { SelectField } from "components/formik/SelectField";
import { ModalHeader } from "components/Modal";
import { Table, TH, TD, TableContainer } from "components/Table";
import { Button } from "components/Button";
import {
  Dropdown,
  OptionsButton,
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuLink,
} from "components/Dropdown";
import { Tooltip } from "components/Tooltip";
import { EditUserModal } from "./EditUserModal";

interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: "admin" | "member" | "restricted_member";
  insertedAt: string;
  confirmedAt: void | string;
  availableServices: string[];
}

const ORGANIZATION_USERS = gql`
  query OrganizationUsers {
    appSettings {
      allowUserInvites
    }
    organizationUsers {
      users {
        id
        firstName
        lastName
        email
        role
        insertedAt
        confirmedAt
        availableServices
      }
    }
  }
`;

interface OrgUsersData {
  appSettings: {
    allowUserInvites: boolean;
  };
  organizationUsers: {
    users: User[];
  };
}

const RESEND_INVITE = gql`
  mutation ResendInvite($id: UUID4!) {
    resendInvite(id: $id) {
      errors {
        key
        message
      }
    }
  }
`;

interface MutationData {
  resendInvite: {
    errors?: InputError[];
  };
}

/**
 * ActionsDropdown.
 */

interface ActionsDropdownProps {
  user: User;
  onEditUserClick(user: User): void;
}

const ActionsDropdown: FC<ActionsDropdownProps> = (props) => {
  const { user, onEditUserClick } = props;
  const toast = useToast();
  const [resendInvite] = useMutation<MutationData>(RESEND_INVITE);

  return (
    <Dropdown>
      <OptionsButton label="Actions" />
      <DropdownMenu>
        <DropdownMenuButton onClick={() => onEditUserClick(user)}>
          Edit User
        </DropdownMenuButton>
        <DropdownMenuLink to={`/o/settings/users/${user.id}/login_attempts`}>
          View Login Attempts
        </DropdownMenuLink>
        <DropdownMenuButton
          onClick={() => {
            resendInvite({ variables: { id: user.id } }).then((resp) => {
              if (resp?.data?.resendInvite.errors) {
                resp?.data?.resendInvite.errors.forEach(
                  (error: { key: string; message: string }) => {
                    toast.danger(error.message);
                  }
                );
              } else {
                toast.success("Re-sent invitation!");
              }
            });
          }}
        >
          Re-send Invite
        </DropdownMenuButton>
      </DropdownMenu>
    </Dropdown>
  );
};

const TIME_ZONES = gql`
  query MyTimeZones {
    timeZoneNames
    me {
      id
      timeZoneName
    }
  }
`;

interface TimeZonesData {
  timeZoneNames: string[];
  me: {
    id: string;
    timeZoneName: string;
  };
}

const INVITE_NEW_USER = gql`
  mutation CreateUserInvitation($input: UserInvitationInput!) {
    inviteUser(input: $input) {
      errors {
        key
        message
      }
    }
  }
`;

interface InviteMutationData {
  inviteUser: {
    errors?: InputError[];
  };
}

const roleOptions = [
  { label: "Restricted Member", value: "restricted_member" },
  { label: "Member", value: "member" },
  { label: "Admin", value: "admin" },
];

const NewUserForm: FC = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCloseModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const openModal = useCallback(() => {
    setModalOpen(true);
  }, []);

  const toast = useToast();
  const { data, loading, error } = useQuery<TimeZonesData>(TIME_ZONES);
  const [inviteNewUser] = useMutation<InviteMutationData>(INVITE_NEW_USER);

  return (
    <div className="text-right">
      <Button type="button" kind="primary" color="teal" onClick={openModal}>
        <span className="mr-2">
          <FAIcon icon="user-plus" />
        </span>
        Add User
      </Button>
      <ReactModal
        isOpen={modalOpen}
        onRequestClose={handleCloseModal}
        className="Modal"
        overlayClassName="Overlay"
        closeTimeoutMS={150}
      >
        <div className="bg-white rounded-lg shadow-lg">
          <ModalHeader
            icon="user-plus"
            title="Add a User"
            onClose={handleCloseModal}
          />
          <div style={{ padding: "1rem" }}>
            {loading ? (
              <h1>Loading...</h1>
            ) : error || !data ? (
              <div style={{ padding: "1rem" }}>
                <h1>Error Loading</h1>
              </div>
            ) : (
              <Formik
                initialValues={{
                  email: "",
                  firstName: "",
                  lastName: "",
                  role: "member",
                  timeZoneName: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email("Invalid email")
                    .required("Required"),
                  firstName: Yup.string().required("Required"),
                  lastName: Yup.string().required("Required"),
                  role: Yup.string().required("Required"),
                  timeZoneName: Yup.string().required("Required"),
                })}
                onSubmit={(values, { setStatus, setSubmitting }) => {
                  setStatus({ errors: null });
                  inviteNewUser({
                    variables: { input: values },
                    refetchQueries: [{ query: ORGANIZATION_USERS }],
                  }).then((res) => {
                    if (res && res.data) {
                      if (res.data.inviteUser.errors) {
                        setStatus({
                          errors: res.data.inviteUser.errors,
                        });
                      } else {
                        toast.success(`Added ${values.email}`);
                        handleCloseModal();
                      }
                    }
                    setSubmitting(false);
                  });
                }}
              >
                {({ status, isSubmitting, handleSubmit }) => (
                  <form onSubmit={handleSubmit}>
                    <FormStatusErrors status={status} />

                    <div className="gap-x-2 grid grid-cols-2">
                      <TextField name="firstName" label="First Name" />

                      <TextField name="lastName" label="Last Name" />
                    </div>

                    <div className="mt-3">
                      <TextField name="email" label="Email" icon="envelope" />
                    </div>

                    <div className="gap-x-2 grid grid-cols-2 mt-3">
                      <SelectField
                        name="role"
                        label="Role"
                        options={roleOptions}
                      />

                      <SelectField
                        name="timeZoneName"
                        label="Time Zone"
                        options={data.timeZoneNames.map((tz: string) => ({
                          value: tz,
                          label: tz,
                        }))}
                      />
                    </div>

                    <div className="mt-6 text-center">
                      <Button
                        type="submit"
                        kind="primary"
                        color="teal"
                        isLoading={isSubmitting}
                        disabled={isSubmitting}
                      >
                        Invite User
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
};

/**
 * OrganizationUsers
 */

interface OrganizationUsersProps {}

type ActiveModal = { type: "EDIT_USER"; userId: string };

export const OrganizationUsers: FC<OrganizationUsersProps> = () => {
  const [activeModal, setActiveModal] = useState<ActiveModal | null>(null);
  const { loading, data, error, refetch } = useQuery<OrgUsersData>(
    ORGANIZATION_USERS
  );

  function closeModal() {
    setActiveModal(null);
  }

  return (
    <>
      {!!activeModal?.userId ? (
        <EditUserModal
          userId={activeModal.userId}
          isOpen={activeModal?.type === "EDIT_USER"}
          onClose={closeModal}
          onSuccess={() => {
            refetch();
            closeModal();
          }}
        />
      ) : null}
      <div className="bg-white rounded-lg shadow-lg">
        <NavTitle title="Settings » Users" />
        <GradientHeader
          title="Users"
          subtitle="Manage your organization's user accounts."
        />
        <div style={{ padding: "1rem" }}>
          {loading ? (
            <h1>Loading...</h1>
          ) : error || !data ? (
            <div style={{ padding: "1rem" }}>
              <h1>Error Loading</h1>
            </div>
          ) : (
            <>
              <div className="mb-4">
                {data.appSettings.allowUserInvites ? (
                  <NewUserForm />
                ) : (
                  <div className="text-right">
                    <Tooltip tip="Users are required to use SSO">
                      <Button
                        type="button"
                        kind="primary"
                        color="teal"
                        disabled
                      >
                        <span className="mr-2">
                          <FAIcon icon="user-plus" />
                        </span>
                        Add User
                      </Button>
                    </Tooltip>
                  </div>
                )}
              </div>
              <TableContainer>
                <Table>
                  <thead>
                    <tr>
                      <TH>Name</TH>
                      <TH>Email</TH>
                      <TH>Apps</TH>
                      <TH>Role</TH>
                      <TH>Created At</TH>
                      <TH />
                    </tr>
                  </thead>
                  <tbody>
                    {data.organizationUsers.users.map((user: User) => (
                      <tr key={user.id}>
                        <TD>
                          {user.firstName} {user.lastName}
                        </TD>
                        <TD>
                          {user.email}{" "}
                          {!user.confirmedAt ? (
                            <Tooltip tip="User has not confirmed email">
                              <FAIcon
                                icon="exclamation-triangle"
                                className="ml-2 text-yellow-400"
                              />
                            </Tooltip>
                          ) : null}
                        </TD>
                        <TD>
                          <ul className="text-xs list-disc">
                            {user.availableServices.map((s) => (
                              <li key={s}>{serviceLabel(s)}</li>
                            ))}
                          </ul>
                        </TD>
                        <TD className="capitalize">{user.role}</TD>
                        <TD>
                          {lightFormat(parseISO(user.insertedAt), "MM/dd/yyyy")}
                        </TD>
                        <TD style={{ textAlign: "center" }}>
                          <ActionsDropdown
                            user={user}
                            onEditUserClick={(user) =>
                              setActiveModal({
                                type: "EDIT_USER",
                                userId: user.id,
                              })
                            }
                          />
                        </TD>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </TableContainer>
            </>
          )}
        </div>
      </div>
    </>
  );
};

function serviceLabel(service: string): string {
  const names: JSONObject = { p2p: "P2P", aaas: "Appeals" };
  return names[service] || service;
}
