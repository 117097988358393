import React, { FC } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CircleIcon.css";

type CircleIconProps = {
  icon: IconProp;
  size?: number;
  iconSize?: number;
  color?: AppColor;
  className?: string;
};

export const CircleIcon: FC<CircleIconProps> = (props) => {
  const {
    icon,
    size,
    iconSize,
    color = "accent-green",
    className = "",
  } = props;

  return (
    <div
      className={`CircleIcon__icon-container CircleIcon__icon-container--${color} ${className}`}
      style={!!size ? { width: size, height: size } : {}}
    >
      {!!iconSize ? (
        <span style={{ fontSize: iconSize }}>
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : (
        <FontAwesomeIcon icon={icon} />
      )}
    </div>
  );
};
