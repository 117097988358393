import { FC, useCallback } from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "react-apollo";
import * as Yup from "yup";
import { Spinner } from "components/Spinner";
import { useToast } from "layouts/PortalLayout/Toast";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalSelectField } from "components/formik/SelectField";
import { Modal, ModalHeader } from "components/Modal";
import { Button } from "components/Button";
import { HorizontalSingleCheckboxField } from "components/formik/SingleCheckboxField";
import { HorizontalCheckboxesField } from "components/formik/CheckboxesField";

const GET_USER = gql`
  query GetUser($id: UUID4!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      role
      isClinical
      availableServices
    }
  }
`;

interface Data {
  user: UserModel;
}

interface Variables {
  id: string;
}

const UPDATE_USER = gql`
  mutation UpdateUser($id: UUID4!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      errors {
        key
        message
      }
      user {
        id
        firstName
        lastName
        email
        role
        isClinical
        availableServices
      }
    }
  }
`;

interface MutationData {
  updateUser: {
    errors?: InputError[];
    user?: UserModel;
  };
}

interface MutationVariables {
  id: string;
  input: FormValues;
}

interface UserModel {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isClinical: boolean;
  availableServices: string[];
}

const availableServicesOptions = [
  { value: "p2p", label: "P2P" },
  { value: "aaas", label: "Appeals" },
];

const roleOptions = [
  { value: "admin", label: "Admin" },
  { value: "member", label: "Member" },
  { value: "restricted_member", label: "Restricted Member" },
];

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
  isClinical: boolean;
  availableServices: string[];
}

const validationSchema: Yup.SchemaOf<FormValues> = Yup.object()
  .shape({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    role: Yup.string().required("Required"),
    isClinical: Yup.bool(),
    availableServices: Yup.array().of(Yup.string()),
  })
  .required();

/**
 * EditUserModal.
 */

interface EditUserModalProps {
  userId: string;
  isOpen: boolean;
  onClose(): void;
  onSuccess(): void;
}

export const EditUserModal: FC<EditUserModalProps> = (props) => {
  const { userId, isOpen, onClose, onSuccess } = props;

  const { data, loading, error } = useQuery<Data, Variables>(GET_USER, {
    variables: { id: userId },
    fetchPolicy: "network-only",
  });

  const [updateUser] = useMutation<MutationData, MutationVariables>(
    UPDATE_USER
  );

  const toast = useToast();

  const onSubmit = useCallback(
    async (values: FormValues, formikActions) => {
      const { setStatus, setSubmitting } = formikActions;

      setStatus({ errors: null });

      try {
        const { data } = await updateUser({
          variables: { id: userId, input: values },
        });

        if (data?.updateUser.errors) {
          setStatus({ errors: data.updateUser.errors });
        } else if (data?.updateUser.user) {
          // it worked...
          toast.success("User updated.");
          return onSuccess();
        }
      } catch (e) {
        console.error(e);
        toast.danger("Something went wrong.");
      }
      setSubmitting(false);
    },
    [userId, updateUser, toast, onSuccess]
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} className="_EditUserModal">
      <ModalHeader title="Edit User" icon="pencil-alt" onClose={onClose} />
      <div className="p-4">
        {loading ? (
          <div className="p-12 text-center">
            <Spinner />
          </div>
        ) : error || !data?.user ? (
          <p>Failed to load</p>
        ) : (
          <Formik<FormValues>
            initialValues={{
              firstName: data.user.firstName,
              lastName: data.user.lastName,
              email: data.user.email,
              role: data.user.role,
              isClinical: data.user.isClinical,
              availableServices: data.user.availableServices,
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />

                <HorizontalTextField name="firstName" label="First Name" />

                <div className="mt-3">
                  <HorizontalTextField name="lastName" label="Last Name" />
                </div>

                <div className="mt-3">
                  <HorizontalTextField name="email" label="Email" />
                </div>

                <div className="mt-3">
                  <HorizontalSelectField
                    name="role"
                    label="Role"
                    options={roleOptions}
                  />
                </div>

                <div className="mt-3">
                  <HorizontalSingleCheckboxField
                    name="isClinical"
                    checkboxLabel={<>Is Clinical?</>}
                    label=""
                  />
                </div>

                <div className="mt-3">
                  <HorizontalCheckboxesField
                    name="availableServices"
                    label="Services"
                    options={availableServicesOptions}
                  />
                </div>

                <div className="flex items-center justify-center gap-3 p-4 mt-4">
                  <Button type="button" kind="secondary" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    kind="primary"
                    color="teal"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Update User
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
