import { Switch, Route } from "react-router-dom";
import { SignInPage } from "pages/SignInPage";
import { OldSignInPage } from "pages/SignInPage";
import { ForgotPasswordPage } from "pages/ForgotPasswordPage";
import { ResetPasswordPage } from "pages/ResetPasswordPage";
import { InvitationRegisterPage } from "pages/InvitationRegisterPage";
import { AuthRoute } from "components/AuthRoutes";

const NoMatch = () => <h1>We couldn't find the page you're looking for</h1>;

export const AuthLayout = () => {
  return (
    <section className="_AuthLayout flex flex-col justify-center min-h-screen bg-teal-900">
      <div className="w-full max-w-5xl mx-auto">
        <Switch>
          <AuthRoute exact path="/auth/sign_in">
            <SignInPage />
          </AuthRoute>

          <AuthRoute exact path="/auth/old_sign_in">
            <OldSignInPage />
          </AuthRoute>

          <AuthRoute exact path="/auth/forgot_password">
            <ForgotPasswordPage />
          </AuthRoute>

          <AuthRoute exact path="/auth/reset_password">
            <ResetPasswordPage />
          </AuthRoute>

          <AuthRoute exact path="/auth/invitation_register">
            <InvitationRegisterPage />
          </AuthRoute>
          <Route component={NoMatch} />
        </Switch>
      </div>
    </section>
  );
};
