import { useCallback, useEffect } from "react";
import Select, {
  createFilter,
  ValueType,
  Props as ReactSelectProps,
  Styles as ReactSelectStyles,
} from "react-select";
import { ErrorMessage, useField } from "formik";
import { VerticalField, HorizontalField } from "../FieldStructure";

export interface StandardOption {
  value: string;
  label: string;
}

function defaultGetOptionLabel(option: Option): string {
  return option.label;
}

function defaultGetOptionValue(option: Option): string {
  return option?.value || "";
}

// NB: This will be the default, per EviCore's request. Pass param `
const filterFromStart = createFilter({ matchFrom: "start" });
const filterFromAny = createFilter({ matchFrom: "any" });

const defaultCustomStyles: any = {
  control: (base: any, state: any) => ({
    ...base,
    "*": {
      boxShadow: "none !important",
    },
  }),
};

/**
 * InputSelect.
 */

export interface InputSelectProps<
  T = StandardOption,
  IsMulti extends boolean = false
> extends Omit<ReactSelectProps<T, IsMulti>, "value" | "onChange"> {
  value: string | string[] | null;
  options: T[];
  onChange(
    value: IsMulti extends true ? readonly string[] : string | null
  ): void;
  autoSelect?: boolean;
  matchFrom?: "start" | "any";
  isMulti?: IsMulti;
}

export function InputSelect<
  T = StandardOption,
  IsMulti extends boolean = false
>(props: InputSelectProps<T, IsMulti>) {
  const {
    value,
    options,
    onChange,
    getOptionLabel = (defaultGetOptionLabel as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionLabel"],
    getOptionValue = (defaultGetOptionValue as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionValue"],
    matchFrom = "start",
    autoSelect = false,
    isMulti = false,
    styles = defaultCustomStyles,
    ...rest
  } = props;

  const selected = (isMulti
    ? (options || []).filter((o) => (value || []).includes(getOptionValue!(o)))
    : options.find((o) => getOptionValue!(o) === value) || null) as ValueType<
    T,
    IsMulti
  >;

  const handleChange = useCallback(
    (option: ValueType<T, IsMulti>) => {
      if (isMulti) {
        return onChange(
          ((option || []) as T[]).map((o) => getOptionValue!(o)) as any
        );
      } else {
        return onChange(option ? getOptionValue(option) : null);
      }
    },
    [isMulti, onChange, getOptionValue]
  );

  useEffect(() => {
    if (autoSelect && options?.length === 1) {
      if (isMulti && ((selected as any) as string[])?.length === 0) {
        onChange([getOptionValue(options[0])] as any);
      } else if (!selected) {
        onChange(getOptionValue(options[0]));
      }
    }
  }, [isMulti, autoSelect, selected, options, onChange, getOptionValue]);

  /**
   * If options change, and the `value` of the field is no longer
   * present in the `options`, unset the value.
   */
  useEffect(() => {
    if (isMulti) {
      if (
        value &&
        value.length > 0 &&
        (!selected || (selected as any).length === 0)
      ) {
        onChange([] as any);
      }
    } else {
      if (value && !selected) {
        onChange(null as any);
      }
    }
  }, [isMulti, value, selected, onChange]);

  return (
    <Select<T, IsMulti>
      {...rest}
      options={options}
      value={selected}
      onChange={handleChange}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      filterOption={matchFrom === "start" ? filterFromStart : filterFromAny}
      isMulti={isMulti as IsMulti}
      styles={styles}
    />
  );
}

/**
 * SelectInput.
 */

interface SelectInputProps<T = Option, IsMulti extends boolean = false> {
  id?: string;
  name: string;
  isLoading?: boolean;
  isClearable?: boolean;
  placeholder?: React.ReactNode;
  options: T[];
  getOptionLabel?: ReactSelectProps<T, IsMulti>["getOptionLabel"]; // (option: ValueType<T, IsMulti>): string;
  getOptionValue?: ReactSelectProps<T, IsMulti>["getOptionValue"]; // (option: ValueType<T, IsMulti>): string;
  components?: any;
  matchFrom?: "start" | "any";
  isMulti?: boolean;
}

export function SelectInput<T = Option, IsMulti extends boolean = false>(
  props: SelectInputProps<T, IsMulti>
) {
  const {
    id,
    name,
    isLoading,
    isClearable,
    placeholder,
    options,
    getOptionLabel = (defaultGetOptionLabel as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionLabel"],
    getOptionValue = (defaultGetOptionValue as unknown) as ReactSelectProps<
      T,
      IsMulti
    >["getOptionValue"],
    components,
    matchFrom = "start",
    isMulti = false,
  } = props;

  const [field, meta, helpers] = useField(name);
  const { value } = field;
  const { setValue, setTouched, setError } = helpers;

  const handleChange = (option: ValueType<T, IsMulti>) => {
    setTouched(true);
    setError(undefined);
    if (isMulti) {
      return setValue(((option || []) as T[]).map((o) => getOptionValue!(o)));
    } else {
      return setValue(option ? getOptionValue!(option as T) : null);
    }
  };

  const handleBlur = () => setTouched(true);

  const selected = (isMulti
    ? (options || []).filter((o) => (value || []).includes(getOptionValue!(o)))
    : options.find((o) => getOptionValue!(o) === value) || null) as ValueType<
    T,
    IsMulti
  >;

  // const customStyles: any = {
  //   control: (base: any, state: any) => ({
  //     ...base,
  //     "*": {
  //       boxShadow: "none !important",
  //     },
  //   }),
  // };
  const controlStyleOverrides = {
    "*": {
      boxShadow: "none !important",
    },
  };
  const showError = meta && meta.touched && meta.error;
  const customStyles: ReactSelectStyles<T, IsMulti> = {
    control: (provided) =>
      showError
        ? {
            ...provided,
            ...controlStyleOverrides,
            border: "1px solid rgb(240, 82, 82)",
          }
        : { ...provided, ...controlStyleOverrides },
  };

  return (
    <>
      <Select<T, IsMulti>
        isLoading={isLoading}
        isClearable={isClearable}
        placeholder={placeholder}
        id={id || name}
        name={name}
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        onChange={handleChange}
        onBlur={handleBlur}
        value={selected}
        components={components}
        filterOption={matchFrom === "start" ? filterFromStart : filterFromAny}
        isMulti={isMulti as any}
        styles={customStyles}
      />
      <ErrorMessage
        component="p"
        name={name}
        className="mt-2 text-xs italic text-red-500"
      />
    </>
  );
}

interface SelectFieldProps<T = Option> extends SelectInputProps<T> {
  label: string;
  indicateRequired?: boolean;
  indicateOptional?: boolean;
}

export function SelectField<T = Option>(props: SelectFieldProps<T>) {
  const { label, indicateRequired, indicateOptional, ...rest } = props;
  return (
    <VerticalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateRequired={indicateRequired}
      indicateOptional={indicateOptional}
    >
      <SelectInput {...rest} />
    </VerticalField>
  );
}

export function HorizontalSelectField<T = Option>(props: SelectFieldProps<T>) {
  const { label, indicateOptional, ...rest } = props;
  return (
    <HorizontalField
      id={`field--${rest.id || rest.name}`}
      htmlFor={rest.id || rest.name}
      label={label}
      indicateOptional={indicateOptional}
    >
      <SelectInput {...rest} />
    </HorizontalField>
  );
}
