import { FC, useCallback } from "react";
import cx from "classnames";
import gql from "graphql-tag";
import { FieldArray, useFormik, FormikProvider } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "react-apollo";
import { SelectField } from "components/formik/SelectField";
import { TextField } from "components/formik/TextField";
import { DateMaskField } from "components/formik/DateMaskField";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { FAIcon } from "components/FAIcon";
import { Tooltip } from "components/Tooltip";
import { localDateToISO, localDateRegex } from "lib/localDateToISO";
import { required } from "components/formik/validation";
import { Button } from "components/Button";
import { CaseLookupParams } from "../NewAppointmentRequestPage";
import { icdRequired } from "lib/stateRegulations";

interface FixedFieldProps<T = string> {
  label: string;
  value?: T;
  tip: string;
}

const FixedField: FC<FixedFieldProps> = (props) => {
  const { label, value, tip } = props;
  return (
    <div className="field">
      <label className="block pt-1 pb-3 text-base font-semibold">{label}</label>
      {value ? (
        <p>
          {value}
          <span className="ml-2 text-purple-400">
            <Tooltip tip={tip} style={{ width: 200 }}>
              <FAIcon icon="question-circle" />
            </Tooltip>
          </span>
        </p>
      ) : (
        <p className="text-gray-700">
          Not set
          <span className="ml-2 text-purple-400">
            <Tooltip tip={tip} style={{ width: 200 }}>
              <FAIcon icon="question-circle" />
            </Tooltip>
          </span>
        </p>
      )}
    </div>
  );
};

export const NEW_CASE_PROFILE_FORM_DATA_QUERY = gql`
  query NewCaseProfileFormData {
    externalSystemNames
    states {
      id
      name
    }
    modalities {
      id
      name
    }
    reviewLevels {
      value
      label
    }
    healthPlans(filter: { active: true }) {
      id
      name
    }
  }
`;

export interface Data {
  externalSystemNames: string[];
  states: StateOption[];
  modalities: ModalityOption[];
  reviewLevels: Option[];
  healthPlans: HealthPlanOption[];
}

export type HealthPlanOption = {
  id: string;
  name: string;
};

export type StateOption = {
  id: string;
  name: string;
};

export type ModalityOption = {
  id: string;
  name: string;
};

const CREATE_DRAFT_APPOINTMENT_REQUEST = gql`
  mutation CreateDraftAppointmentRequest($caseProfiles: [CaseProfileInput!]!) {
    createDraftAppointmentRequest(caseProfiles: $caseProfiles) {
      errors {
        key
        message
      }
      appointmentRequest {
        id
        caseProfiles {
          id
          memberFirstName
        }
      }
    }
  }
`;

interface MutationData {
  createDraftAppointmentRequest: {
    errors?: InputError[];
    appointmentRequest?: {
      id: string;
      caseProfiles: {
        id: string;
        memberFirstName: string;
      }[];
    };
  };
}

const firstCaseProfile = {
  memberFirstName: "",
  memberLastName: "",
  memberDob: "",
  healthPlanId: "",
  healthPlanType: "",
  memberMembershipNumber: "",
  icd10Code: "",
  memberStateId: "",
  modalityId: "",
  caseReferenceNumber: "",
  levelOfReview: "",
  externalSystemName: "",
};

const additionalCaseProfile = {
  memberFirstName: "",
  memberLastName: "",
  memberDob: "",
  healthPlanId: "",
  healthPlanType: "",
  memberMembershipNumber: "",
  icd10Code: "",
  memberStateId: "WILL_BE_REPLACED_THIS_IS_JUST_TO_PASS_VALIDATION",
  modalityId: "WILL_BE_REPLACED_THIS_IS_JUST_TO_PASS_VALIDATION",
  caseReferenceNumber: "",
  levelOfReview: "WILL_BE_REPLACED_THIS_IS_JUST_TO_PASS_VALIDATION",
  externalSystemName: "WILL_BE_REPLACED_THIS_IS_JUST_TO_PASS_VALIDATION",
};

const currentYear = new Date().getFullYear();
const dobMinYear = 1900;
const dobMaxYear = currentYear + 1;

/**
 * CreateProfileForm.
 */

const validationSchema = Yup.object().shape({
  caseProfiles: Yup.array().of(
    Yup.object().shape({
      memberFirstName: Yup.string().required("Required"),
      memberLastName: Yup.string().required("Required"),
      memberDob: Yup.string()
        .required("Required")
        .matches(localDateRegex, {
          message: "Invalid date",
        }),
      healthPlanId: Yup.string().required("Required"),
      memberMembershipNumber: Yup.string().required("Required"),
      memberStateId: Yup.string().required("Required"),
      modalityId: Yup.string().required("Required"),
      caseReferenceNumber: Yup.string().required("Required"),
      levelOfReview: Yup.string().required("Required"),
      externalSystemName: Yup.string().required("Required"),
      icd10Code: Yup.string(),
    })
  ),
});

interface CaseProfileFormProps {
  caseLookups: CaseLookupParams[] | null;
  onCreate(caseProfileId: string): void;
}

export const CaseProfileForm: FC<CaseProfileFormProps> = ({
  caseLookups,
  onCreate,
}) => {
  const { data, loading, error } = useQuery<Data>(
    NEW_CASE_PROFILE_FORM_DATA_QUERY
  );

  const [createDraftAppointmentRequest] = useMutation<MutationData>(
    CREATE_DRAFT_APPOINTMENT_REQUEST
  );

  const onSubmit = useCallback(
    (values, { setStatus, setSubmitting }) => {
      setStatus({ errors: null });
      const vals = {
        caseProfiles: values.caseProfiles
          .map((cp: any) => ({
            ...cp,
            levelOfReview: unaliasedLOR(cp.levelOfReview),
            memberDob: localDateToISO(cp.memberDob),
          }))
          .map((cp: any, idx: number) => {
            if (idx === 0) {
              return cp;
            } else {
              const first = values.caseProfiles[0];
              return {
                ...cp,
                memberStateId: first.memberStateId,
                modalityId: first.modalityId,
                levelOfReview: first.levelOfReview,
                externalSystemName: first.externalSystemName,
              };
            }
          }),
      };
      return createDraftAppointmentRequest({
        variables: vals,
      }).then(
        (resp) => {
          if (resp?.data?.createDraftAppointmentRequest.errors) {
            setStatus({
              errors: resp.data.createDraftAppointmentRequest.errors,
            });
          } else if (
            resp?.data?.createDraftAppointmentRequest.appointmentRequest
          ) {
            onCreate(
              resp.data.createDraftAppointmentRequest.appointmentRequest.id
            );
          }
          setSubmitting(false);
        },
        (rej) => setSubmitting(false)
      );
    },
    [createDraftAppointmentRequest, onCreate]
  );

  function stateNameById(id: string) {
    return data?.states.find((s) => s.id === id)?.name || "";
  }

  const formikBag = useFormik({
    initialValues: initialValues(caseLookups),
    validationSchema,
    onSubmit,
  });

  const { status, isSubmitting, values, handleSubmit } = formikBag;

  // NB: We only need to look at the first case because they all have
  //     to have the same state.

  const needIcd =
    values.caseProfiles.length > 0 &&
    icdRequired(
      stateNameById(values.caseProfiles[0].memberStateId),
      values.caseProfiles[0].healthPlanType
    );

  return loading ? (
    <h1>Loading</h1>
  ) : error || !data ? (
    <div style={{ padding: "1.5rem" }}>
      <h1>Failed to Load</h1>
    </div>
  ) : (
    <FormikProvider value={formikBag}>
      <form onSubmit={handleSubmit}>
        <FormStatusErrors status={status} />
        <FieldArray
          name="caseProfiles"
          render={(arrayHelpers) => (
            <div>
              {values.caseProfiles && values.caseProfiles.length > 0 ? (
                <div>
                  {values.caseProfiles.map((_caseProfile, index) => (
                    <div
                      key={index}
                      className={cx({
                        "border border-gray-200 mb-4 p-4 pb-8 rounded-lg shadow-md":
                          values.caseProfiles.length > 1,
                      })}
                    >
                      {values.caseProfiles.length > 1 ? (
                        <div className="flex items-center justify-between mb-6">
                          <p
                            className="mb-4 text-sm text-gray-700"
                            style={{ margin: 0 }}
                          >
                            Case #{index + 1}
                          </p>
                          <button
                            type="button"
                            className="text-sm font-semibold text-red-500 bg-transparent"
                            style={{
                              border: "none",
                              padding: 0,
                              display: "inline",
                              cursor: "pointer",
                            }}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            Remove
                          </button>
                        </div>
                      ) : null}
                      <div className="grid grid-cols-6 gap-3">
                        <div className="col-span-3">
                          <TextField
                            name={`caseProfiles[${index}].caseReferenceNumber`}
                            label="Case Reference Number"
                          />
                        </div>

                        <div className="col-span-3">
                          <DateMaskField
                            className="col-span-3"
                            name={`caseProfiles[${index}].memberDob`}
                            label="Member Date of Birth"
                            minYear={dobMinYear}
                            maxYear={dobMaxYear}
                          />
                        </div>

                        <div className="col-span-2">
                          <TextField
                            name={`caseProfiles[${index}].memberMembershipNumber`}
                            label="Member ID"
                          />
                        </div>

                        <div className="col-span-2">
                          <TextField
                            name={`caseProfiles[${index}].memberFirstName`}
                            label="Member First Name"
                          />
                        </div>

                        <div className="col-span-2">
                          <TextField
                            name={`caseProfiles[${index}].memberLastName`}
                            label="Member Last Name"
                          />
                        </div>

                        <div className={`col-span-${needIcd ? "2" : "3"}`}>
                          {index === 0 ? (
                            <SelectField
                              name={`caseProfiles[${index}].memberStateId`}
                              label="Member State"
                              options={data.states.map((s) => ({
                                value: s.id,
                                label: s.name,
                              }))}
                            />
                          ) : (
                            <FixedField
                              tip="Cases must all have same Member State or be submitted as separate requests"
                              label="Member State"
                              value={
                                (
                                  data.states.find(
                                    (s) =>
                                      s.id ===
                                      values.caseProfiles[0].memberStateId
                                  ) || { name: undefined }
                                ).name
                              }
                            />
                          )}
                        </div>

                        {needIcd ? (
                          <div className="col-span-2">
                            <TextField
                              name={`caseProfiles[${index}].icd10Code`}
                              label="ICD10 Code"
                              validate={required}
                            />
                          </div>
                        ) : null}

                        <div className={`col-span-${needIcd ? "2" : "3"}`}>
                          <SelectField
                            name={`caseProfiles[${index}].healthPlanId`}
                            label="Health Plan"
                            options={data.healthPlans.map((hp) => ({
                              value: hp.id,
                              label: hp.name,
                            }))}
                          />
                        </div>

                        <div className="col-span-2">
                          {index === 0 ? (
                            <SelectField
                              name={`caseProfiles[${index}].modalityId`}
                              label="Modality"
                              options={data.modalities.map((m) => ({
                                value: m.id,
                                label: m.name,
                              }))}
                            />
                          ) : (
                            <FixedField
                              tip="Cases must all have same Modality or be submitted as separate requests"
                              label="Modality"
                              value={
                                (
                                  data.modalities.find(
                                    (m) =>
                                      m.id === values.caseProfiles[0].modalityId
                                  ) || { name: undefined }
                                ).name
                              }
                            />
                          )}
                        </div>

                        <div className="col-span-2">
                          {index === 0 ? (
                            <SelectField
                              name={`caseProfiles[${index}].levelOfReview`}
                              label="Level of Review"
                              options={data.reviewLevels}
                            />
                          ) : (
                            <FixedField
                              tip="Cases must all have same Level of Review or be submitted as separate requests"
                              label="Level of Review"
                              value={
                                (
                                  data.reviewLevels.find(
                                    (rl) =>
                                      rl.value ===
                                      values.caseProfiles[0].levelOfReview
                                  ) || { label: undefined }
                                ).label
                              }
                            />
                          )}
                        </div>

                        <div className="col-span-2">
                          {index === 0 ? (
                            <SelectField
                              name={`caseProfiles[${index}].externalSystemName`}
                              label="System Name"
                              options={data.externalSystemNames.map((name) => ({
                                value: name,
                                label: name,
                              }))}
                            />
                          ) : (
                            <FixedField
                              tip="Cases must all have same System Name or be submitted as separate requests"
                              label="System Name"
                              value={values.caseProfiles[0].externalSystemName}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {process.env.REACT_APP_COMPOUND_REQUESTS_DISABLED !==
                    "true" &&
                  values.caseProfiles &&
                  values.caseProfiles.length < 4 ? (
                    <div className="py-4 mt-4 text-center">
                      <Button
                        type="button"
                        kind="tertiary"
                        className="border"
                        size="xs"
                        onClick={() => arrayHelpers.push(additionalCaseProfile)}
                      >
                        <span className="mr-2">
                          <FAIcon icon="plus" />
                        </span>
                        Add Another Case
                      </Button>
                    </div>
                  ) : null}
                </div>
              ) : (
                <p>No cases being input</p>
              )}
            </div>
          )}
        />
        <div className="flex justify-end mt-4">
          <Button
            type="submit"
            kind="primary"
            color="teal"
            size="lg"
            disabled={isSubmitting}
            isLoading={isSubmitting}
          >
            Continue
          </Button>
        </div>
      </form>
    </FormikProvider>
  );
};

function initialValues(caseLookups: CaseLookupParams[] | null) {
  if (caseLookups && caseLookups.length > 0) {
    return {
      caseProfiles: caseLookups.map((cl) => ({
        caseReferenceNumber: cl.caseReferenceNumber,
        memberDob: cl.memberDob,
        memberMembershipNumber: "",
        memberFirstName: "",
        memberLastName: "",
        memberStateId: "",
        healthPlanId: cl.healthPlanId,
        healthPlanType: "",
        modalityId: "",
        levelOfReview: "",
        externalSystemName: "",
        icd10Code: "",
      })),
    };
  } else {
    return { caseProfiles: [firstCaseProfile] };
  }
}

function unaliasedLOR(lor: string): string {
  return lor === "post_review_consult" ? "reconsideration" : lor;
}
