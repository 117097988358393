import { FC } from "react";
import { useQuery, useMutation } from "react-apollo";
import gql from "graphql-tag";
import * as Yup from "yup";
import { useToast } from "layouts/PortalLayout/Toast";
import { Formik } from "formik";
import { FormStatusErrors } from "components/formik/FormStatusErrors";
import { HorizontalTextField } from "components/formik/TextField";
import { HorizontalSelectField } from "components/formik/SelectField";
import { HorizontalToggleSwitchField } from "components/formik/ToggleSwitchField";
import { Modal, ModalHeader } from "components/Modal";
import { HEALTH_PLANS, HealthPlan } from "../HealthPlans";
import { Spinner } from "components/Spinner";
import { Button } from "components/Button";

const HEALTH_PLAN = gql`
  query FetchHealthPlan($id: UUID4!) {
    healthPlan(id: $id) {
      id
      name
      evicoreId
      externalSystemName
      active
      appealP2pCompletionEmailEnabled
    }
    externalSystemNames
  }
`;

interface Data {
  healthPlan: HealthPlan;
  externalSystemNames: string[];
}

const UPDATE_HEALTH_PLAN = gql`
  mutation UpdateHealthPlan($id: UUID4!, $input: HealthPlanInput!) {
    updateHealthPlan(id: $id, input: $input) {
      errors {
        key
        message
      }
      healthPlan {
        id
        name
        evicoreId
        externalSystemName
        active
        appealP2pCompletionEmailEnabled
      }
    }
  }
`;

interface MutationData {
  updateHealthPlan: {
    errors?: InputError[];
    healthPlan?: HealthPlan;
  };
}

interface EditHealthPlanModalProps {
  healthPlanId: string;
  isOpen: boolean;
  onClose(): void;
}

export const EditHealthPlanModal: FC<EditHealthPlanModalProps> = (props) => {
  const { isOpen, healthPlanId, onClose } = props;

  const toast = useToast();
  const { data, loading, error } = useQuery<Data>(HEALTH_PLAN, {
    variables: { id: healthPlanId },
    fetchPolicy: "network-only",
  });
  const [updateHealthPlan] = useMutation<MutationData>(UPDATE_HEALTH_PLAN);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="EditHealthPlanModal"
    >
      <ModalHeader
        title="Edit Health Plan"
        icon="pencil-alt"
        onClose={onClose}
      />
      <div className="p-4">
        {loading ? (
          <div className="p-8 text-center">
            <Spinner />
          </div>
        ) : error || !(data && data.healthPlan && data.externalSystemNames) ? (
          <p>Failed to load</p>
        ) : (
          <Formik
            initialValues={{
              name: data.healthPlan.name,
              evicoreId: data.healthPlan.evicoreId,
              externalSystemName: data.healthPlan.externalSystemName,
              active: data.healthPlan.active,
              appealP2pCompletionEmailEnabled:
                data.healthPlan.appealP2pCompletionEmailEnabled,
            }}
            validationSchema={Yup.object().shape({
              name: Yup.string().required("Required"),
              evicoreId: Yup.string().required("Required"),
              externalSystemName: Yup.string().required("Required"),
              active: Yup.bool(),
            })}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              setStatus({ errors: null });
              updateHealthPlan({
                variables: { id: healthPlanId, input: values },
                refetchQueries: [{ query: HEALTH_PLANS }],
              }).then((resp) => {
                if (resp?.data?.updateHealthPlan.errors) {
                  setStatus({
                    errors: resp.data.updateHealthPlan.errors,
                  });
                } else {
                  toast.success("Updated health plan!");
                  onClose();
                }
                setSubmitting(false);
              });
            }}
          >
            {({ status, isSubmitting, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <FormStatusErrors status={status} />
                <HorizontalTextField name="name" label="Name" />
                <div className="mt-3">
                  <HorizontalTextField name="evicoreId" label="EviCore ID" />
                </div>
                <div className="mt-3">
                  <HorizontalSelectField
                    name="externalSystemName"
                    label="System Name"
                    options={data.externalSystemNames.map((name) => ({
                      value: name,
                      label: name,
                    }))}
                  />
                </div>
                <div className="mt-3">
                  <HorizontalToggleSwitchField
                    name="active"
                    label="Active or Inactive"
                    toggleSwitchProps={{
                      showLabel: true,
                      checkedLabel: "Active",
                      uncheckedLabel: "Inactive",
                    }}
                  />
                </div>
                <div className="mt-3">
                  <HorizontalToggleSwitchField
                    name="appealP2pCompletionEmailEnabled"
                    label="Send email upon Appeal P2P Completion"
                    toggleSwitchProps={{
                      showLabel: true,
                      checkedLabel: "Enabled",
                      uncheckedLabel: "Disabled",
                    }}
                  />
                </div>
                <div className="mt-6 text-center">
                  <Button
                    type="submit"
                    kind="primary"
                    color="teal"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Update Health Plan
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
