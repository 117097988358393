import React from "react";
import cx from "classnames";
import { getMonth, lightFormat } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { TimeRange } from "../types";
import "./DateRangeDropdown.css";

import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { FAIcon } from "components/FAIcon";
import { Button } from "components/Button";

interface DateRangeDropdownProps {
  value: TimeRange;
  onChange(timeRange: TimeRange): void;
}

interface DateRangeDropdownState {
  dateRangePicker: {
    selection: {
      startDate: Date;
      endDate: Date;
      key: "selection";
    };
  };
  isOpen: boolean;
}

class DateRangeDropdown extends React.Component<
  DateRangeDropdownProps,
  DateRangeDropdownState
> {
  constructor(props: DateRangeDropdownProps) {
    super(props);
    this.state = {
      dateRangePicker: {
        selection: {
          startDate: this.props.value.start,
          endDate: this.props.value.finish,
          key: "selection",
        },
      },
      isOpen: false,
    };
  }

  toggleOpen = () => this.setState((state) => ({ isOpen: !state.isOpen }));

  handleDateRangeSelect = (payload: {
    selection: { startDate: Date; endDate: Date; key: "selection" };
  }) => {
    this.setState({
      dateRangePicker: payload,
    });
  };

  handleApply = () => {
    const { onChange } = this.props;
    const { dateRangePicker } = this.state;
    if (onChange) {
      const timeRange: TimeRange = {
        start: dateRangePicker.selection.startDate,
        finish: dateRangePicker.selection.endDate,
      };
      onChange(timeRange);
      this.toggleOpen();
    }
  };

  /**
   * Reset dateRange selection value to the value of the prop `value` and close
   * the dropdown.
   */
  cancel = () => {
    const { value } = this.props;
    this.setState({
      dateRangePicker: {
        selection: {
          startDate: value.start,
          endDate: value.finish,
          key: "selection",
        },
      },
      isOpen: false,
    });
  };

  render() {
    const { dateRangePicker, isOpen } = this.state;
    const {
      value: { start, finish },
    } = this.props;

    return (
      <div className="DateRangeDropdown">
        <div className="DateRangeDropdown__container">
          <div className="DateRangeDropdown__trigger" onClick={this.toggleOpen}>
            <div className="DateRangeDropdown__trigger__date">
              {formatDate(start)}
            </div>
            <div className="DateRangeDropdown__trigger__date-divider">
              <FAIcon icon="arrow-right" />
            </div>
            <div className="DateRangeDropdown__trigger__date">
              {formatDate(finish)}
            </div>
            <div className="DateRangeDropdown__trigger__chevron">
              <FAIcon icon="chevron-down" />
            </div>
          </div>
          <div
            className={cx("DateRangeDropdown__content", {
              "DateRangeDropdown__content--open": isOpen,
              "DateRangeDropdown__content--closed": !isOpen,
            })}
          >
            <div className="DateRangeDropdown__content__body">
              <DateRangePicker
                ranges={[dateRangePicker.selection]}
                onChange={this.handleDateRangeSelect}
                moveRangeOnFirstSelection={false}
                showSelectionPreview
              />
            </div>
            <div className="DateRangeDropdown__content__footer">
              <Button type="button" size="sm" onClick={this.cancel}>
                Cancel
              </Button>
              <Button
                type="button"
                kind="primary"
                color="teal"
                size="sm"
                onClick={this.handleApply}
              >
                Apply
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function formatDate(date: Date): string {
  return `${monthStr(date)} ${lightFormat(date, "d, yyyy")}`;
}

const monthStrings = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

function monthStr(date: Date): string {
  return monthStrings[getMonth(date)];
}

export { DateRangeDropdown };
